import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { BasicSectionContainer, CarouselSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { PersonalityTile } from '~/components/panels/tiles/PersonalityTile'
import { useEvaluationContext } from '~/context/EvaluationContext'
import { Button } from '~/elements/buttons/Button'
import { ScrollView } from '~/elements/containers/ScrollView'
import Overlay from '~/elements/overlays/Overlay'
import { ContentPageParagraph } from '~/elements/text/Player.FontTypes'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { prefixMatch } from '~/theme/tailwind'

export const PersonalityTestSection = () => {
  const { t } = useTranslation(['profile', 'common'])

  const [isDetailsClicked, setIsDetailsClicked] = useState(false)

  const evaluationContext = useEvaluationContext()

  if (evaluationContext.isLoading) {
    return <LoadingScreen />
  }
  if (!evaluationContext.data) {
    return null
  }

  const {
    data: {
      doc: { id },
      model: {
        scoreIntro, dimensionGroups, toc,
      },
    },
  } = evaluationContext
  // console.log("toc", toc)

  const getPersonalityTiles = (orientation: 'portrait' | 'landscape') => dimensionGroups.map((dimensionGroup) => {
    const targetPageIndex = toc.findIndex((content) => content.key === dimensionGroup.key) + 1
    const to = `/evaluations/${id}/page/${targetPageIndex}`
    return (
      <PersonalityTile
        key={dimensionGroup.key}
        to={to}
        title={dimensionGroup.title}
        excerpt={dimensionGroup.excerpt}
        illustration={dimensionGroup.illustration}
        orientation={orientation}
      />
    )
  })

  return (

    <>
      <SectionHeading title={t('profile:personality.title')} description={scoreIntro} descriptionNumberOfLines={2} tw="px-4 md:px-8 max-w-xlp" />
      <BasicSectionContainer tw="">
        <Button title={t('common:cta.details')} variant="ghost" onPress={() => setIsDetailsClicked(true)} tw="justify-start uppercase text-gray-500" />
        <Overlay isVisible={isDetailsClicked} onBackdropPress={() => setIsDetailsClicked(false)}>
          <Overlay.Content scrollEnabled tw="p-14">
            <ContentPageParagraph tw="text-md max-w-none">{scoreIntro}</ContentPageParagraph>
          </Overlay.Content>
        </Overlay>
      </BasicSectionContainer>

      {!prefixMatch('lg') ? (
        <CarouselSectionContainer tw="pr-0">
          <ScrollView horizontal showsHorizontalScrollIndicator={false} tw="w-full mt-4 flex flex-row">
            {getPersonalityTiles('portrait')}
          </ScrollView>
        </CarouselSectionContainer>
      ) : (
        <BasicSectionContainer tw="mt-4">
          {getPersonalityTiles('landscape')}
        </BasicSectionContainer>
      )}
    </>
  )
}
