// eslint-disable-next-line @typescript-eslint/no-var-requires
const base = require('./tailwind.config.js')

module.exports = {
  ...base,
  // @see https://tailwindcss.com/docs/configuration#core-plugins
  // we remove plugins that are not compatible with react native styles
  corePlugins: {
    ...base.corePlugins,
    boxShadow: false, // different in React Native
    boxShadowColor: false,
    ringColor: false,
    ringOffsetColor: false,
    ringOffsetWidth: false,
    ringOpacity: false,
    ringWidth: false,
    scale: false,
    transform: false,
    transitionDelay: false,
    transitionDuration: false,
    transitionProperty: false,
    transitionTimingFunction: false,
    translate: false,
  },
}
