import { library } from '@fortawesome/fontawesome-svg-core'
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'
import { openBrowserAsync } from 'expo-web-browser'
import get from 'lodash/get'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { Avatar } from '~/elements/avatar/Avatar'
import { Badge } from '~/elements/badge/Badge'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { Text } from '~/elements/text/Text'
import { useContractorBySlug } from '~/hooks/dato.contractors'
import { useCurrentUserMinderSlug } from '~/hooks/minder'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { prefixMatch } from '~/theme/tailwind'
import { getFullName } from '~/utils/people.helpers'

import { AuthorContentSection } from './AuthorContentSection'
import { MinderSinceBadge } from './MinderSinceBadge'

library.add(faLocationDot)

type MinderScreenProps = Record<string, never>

export const MinderScreen:FC<MinderScreenProps> = ({ route }) => {
  const { t } = useTranslation(['track', 'common'])

  const slug = get(route, 'params.slug')
  // console.log('slug', slug)

  const { isLoading: minderLoading, data: minderRecord } = useContractorBySlug(slug)

  const { isLoading: currentUserLoading, data: currentUserMinderSlug } = useCurrentUserMinderSlug()

  const isCurrentUserMinder = currentUserMinderSlug === slug

  if (minderLoading || currentUserLoading || !minderRecord) {
    return <LoadingScreen />
  }

  // console.log('data', data)

  const {
    minder, bio, expertises, email, contentProducer,
  } = minderRecord.contractor

  const { picture, home, firstName } = minder

  const fullName = getFullName(minderRecord.contractor.minder)

  return (
    <MainContainer scrollEnabled contentContainerTw="flex-col items-start w-full">
      <ScreenHeadingSection title={t('track:minder.heading.title')} tw="mb-4" />
      <BasicSectionContainer tw="">
        <View tw="bg-surface-1 rounded-2xl w-full p-6 flex-col items-start mb-4">
          <View tw="flex-row items-center w-full">
            {picture && <Avatar title={fullName} size="giant" image={picture} tw="border-2 border-white bg-brand-lighter" />}
            <View tw="ml-6 flex-col flex-grow">
              <View tw="flex-row justify-between">
                <Text tw="font-serif font-medium text-xl">{fullName}</Text>
                { prefixMatch('md') && isCurrentUserMinder && <MinderSinceBadge minder={slug} />}
              </View>
              {!!home && home.length > 0 && (
                <View tw="flex-row mt-2">
                  <Icon name="location-dot" />
                  <Text tw="text-sm font-sans font-normal ml-2">{home[0].city}</Text>
                </View>
              )}
            </View>

          </View>
          { !prefixMatch('md') && isCurrentUserMinder && <View tw="mt-2"><MinderSinceBadge minder={slug} /></View>}
          <View tw="flex-row justify-start mt-4">{expertises.map((item) => <Badge key={item.key} value={item.title} tw="mr-2" color="brand" size="small" />)}</View>
          <Text tw="mt-6 text-sm prose leading-normal">{bio}</Text>

          <Button
            title={t('common:cta.contact')}
            color="brand"
            size="l"
            tw="mt-6"
            onPress={() => {
              openBrowserAsync(`mailto:${email}`)
            }}
          />
        </View>

      </BasicSectionContainer>

      {contentProducer && <AuthorContentSection slug={slug} firstName={firstName} />}

    </MainContainer>

  )
}
