import cleanDeep from 'clean-deep'
import { Platform } from 'react-native'

const getGenericOptions = (options = {}, integrations) => cleanDeep({
  ...options,
  integrations,
  context: {
    ...options.context,
    app: {
      name: `${Platform.OS}-app`,
    },
  },
})

export const getIdentifyOptions = (options) => getGenericOptions(options, {
  'Recombee AI': false,
})

export const getTrackOptions = (eventData, options) => getGenericOptions(options, eventData.integrations)

export const getGroupOptions = (options) => getGenericOptions(options, {
  'Recombee AI': false,
})

export const getScreenOptions = (options) => getGenericOptions(options, {
  'Recombee AI': false,
  'Customer.io': false,
})
