import last from 'lodash/last'
import sortBy from 'lodash/sortBy'

import { prefixMatch } from '~/theme/tailwind'

// Note: should take that from config
const breakpoints = ['sm', 'md', 'lg', 'xl', 'xxl']

const getBpIndex = (prefix) => breakpoints.findIndex((item) => item === prefix)

export const getBreakpointIndex = <T>(cn: T): number => {
  let keyIndex = -1
  const keyHasPrefix = cn.includes(':')
  if (keyHasPrefix) {
    const [prefix] = cn.split(':')
    keyIndex = getBpIndex(prefix)
  }
  // console.log(`brakpoint index for ${key} : ${keyIndex}`)

  return keyIndex
}

export const getCurrentBpIndex = () => breakpoints.reverse().findIndex((bp) => prefixMatch(bp) === true)

/**
 * Return classes that are strictly smaller than the referenced breakpoint
 * @param arr - Array of tailwind classes to consider
 * @param bp - breakpoint to consider as a reference
 */
export const getSmallerKeys = (arr, bp) => {
  // console.log(`getSmallerKeys`, arr, bp)
  const bpIndex = getBpIndex(bp)
  // console.log(`reference index`, bpIndex)

  return arr.filter((cn) => {
    let index = -1
    if (cn.includes(':')) {
      const [selector] = cn.split(':')
      if (breakpoints.includes(selector)) {
        index = getBpIndex(selector)
      }
    }
    // console.log(cn + ` index:`, index)

    return index < bpIndex
  })
}

// sm:foo => foo
export const getTwNoPrefix = <S>(cn:S):S => last<S>(cn.split(':'))

/**
 * for an input of classes of same kind, extract the largest applicableClass
 */
export const getApplicableClassForBreakpoint = (arr) => {
  const orderedArr = sortBy(arr, [getBreakpointIndex])
  // console.log('orderedArr', orderedArr)

  const currentBpIndex = getCurrentBpIndex()
  // console.log('currentBpIndex', currentBpIndex)

  let res
  orderedArr.forEach((cn) => {
    const cnIndex = getBreakpointIndex(cn)
    // console.log(`${cn} index:`, cnIndex)
    if (cnIndex <= currentBpIndex) {
      res = getTwNoPrefix(cn)
    }
  })
  // console.log('applicable class', res)

  return res
}

export const splitClassName = (cn) => {
  if (!cn.includes(':')) {
    return { selector: undefined, value: cn }
  }
  const [selector, value] = cn.split(':')

  return { selector, value }
}

export type InteractionState = {
  active?:boolean
  hover?: boolean
  focus?: boolean
}

const interactionSelectors = ['active', 'focus', 'hover']

/**
 * Excludes classes that do not match the interaction state
 */
export const removeNonApplicableInteractionClasses = (arr: Array<string>, is:InteractionState = {}) => {
  const base = interactionSelectors.reduce((acc, key) => ({ ...acc, [key]: is[key] || false }), {})

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inapplicableSelectors = Object.entries(base).filter(([_, value]) => value === false).map((([key]) => key))
  // console.log('inapplicableSelectors', inapplicableSelectors)

  return arr.filter((cn) => !inapplicableSelectors.includes(splitClassName(cn).selector))
}

/**
 * Assuming all input classes refer to the same property, we return a single applicable value
 */
export const keepApplicableInteractionClass = (arr: Array<string>, is:InteractionState = {}) => {
  // most of the time, a single class is provided
  if (arr.length === 1) {
    return arr[0]
  }

  const getInteractionIndex = (cn) => interactionSelectors.indexOf(splitClassName(cn).selector)

  // by convention, we take the latest class defined among (none, active, focus, hover)
  return last(removeNonApplicableInteractionClasses(arr, is).sort((a, b) => {
    const indexA = getInteractionIndex(a)
    const indexB = getInteractionIndex(b)
    if (indexA === indexB) {
      return 0
    }
    if (indexA < indexB) {
      return -1
    }
    if (indexA > indexB) {
      return 1
    }
    throw new Error('unreachable')
  }).map((item) => getTwNoPrefix(item)))
}
