import classNames from 'classnames'

import { useAppBackgroundContext } from '~/context/AppBackgroundContext'
import { View } from '~/elements/containers/View'
import { SvgById } from '~/elements/images/SvgById'
import { GenericApp } from '~/navigators.shared/GenericApp'

import NavigationContainerWrapper from './RootNavigator'

// TODO: be smart about SVG positionning
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AppBackgroundSvg = ({ assetId }) => (
  <View tw="absolute h-full w-full">
    <SvgById
      testID="bg-svg"
      assetId={assetId}
      tw=""
    />
  </View>
)

export const AppWithBgContext = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { bgColor, bgSvgId } = useAppBackgroundContext()

  // console.log(`bgColor`, bgColor)
  return (
    <GenericApp>
      <View tw={classNames('flex-1', bgColor ? `bg-${bgColor}` : '')}>
        {/* {bgSvgId && <AppBackgroundSvg assetId={bgSvgId}/>} */}
        <NavigationContainerWrapper colorScheme="light" />
      </View>
    </GenericApp>
  )
}
