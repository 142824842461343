import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { GroupMeetingSessionDataExtended } from '~/definitions/firestore.groupMeetingSession'
import { BgColorShade } from '~/elements/colors/BgColorShades'
import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'
import { ImageProps } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'
import { useDateFormat } from '~/hooks/dateFns'
import { useDatoSvg } from '~/hooks/dato.assets'

import { ContentTileProps } from './ContentTileProps.d'

library.add(faClock, faUser)

// TODO with all: better DRY, harmonize vocabulary and tiles (landscape, portrait, small, medium, large), example ContentTile

type EventTileProps = Pick<ContentTileProps, 'title' | 'navigateTo' | 'onPress'> & {
  image: {
    id?: string
    foreground: ImageProps
    background: ImageProps
    backgroundColor: BgColorShade
  }
  // real type would be image: GetEventsByAudiences_allConferenceInstances_model_illustration | null | undefined
  // more accurate but creating way more type errors, for instance on DoubleSvg, getColor
  sessions: GroupMeetingSessionDataExtended[] | undefined
}

// #####

type EventTileSimpleProps = Omit<EventTileProps, 'image'>

export const EventTileSimple: FC<EventTileSimpleProps> = ({
  title,
  sessions,
  onPress,
  navigateTo,
}) => {

  const { i18n } = useTranslation()

  // optional placeholder

  const tsDate = sessions
    ? sessions[0].startAt // take what session user subscribed to, or the first of them
    : undefined
  const dateFormatted = `${useDateFormat(tsDate, 'medium', i18n.language)}`

  return (
    <Pressable
      to={navigateTo}
      onPress={onPress}
      tw="flex w-full max-w-xs bg-surface-1 border border-gray-100 p-4 rounded-2xl mr-4"
      >
      <>
        <View tw="flex-col text-start">
          <Text tw="font-sans font-normal text-xs leading-relaxed mt-2 text-gray-700">{dateFormatted}</Text>
          {title && <Text tw="font-serif text-sm font-semibold sm:text-base leading-snug my-2 text-gray-900" numberOfLines={2}>{title}</Text>}
        </View>
        {/* last update design: no cta button anymore, just date and title */}
      </>
    </Pressable>
  )
}

// #####

export const EventTileReplay: FC<EventTileProps> = ({
  title,
  sessions,
  onPress,
  navigateTo,
  image,
}) => {

  // optional placeholder

  const { i18n } = useTranslation()

  const tsDate = sessions
    ? sessions[0].startAt // take the first and probably unique session of the past conference
    : undefined
  const dateFormatted = `${useDateFormat(tsDate, 'medium', i18n.language)}`

  const backgroundReplayTw = `bg-${image.backgroundColor} p-2 w-20 h-20 rounded-lg items-center justify-center`

  const textCardTw = 'flex-col text-left pl-4 w-4/5 h-20 justify-center'

  const { data: imageForeground } = useDatoSvg('39808412')
  // console.log('imageForeground', imageForeground)

  return (
    <Pressable
      to={navigateTo}
      onPress={onPress}
      tw="flex bg-surface-1 border border-gray-100 p-4 rounded-2xl max-h-full w-full mb-4"
      >
      <View tw="flex-row">
        <View tw={backgroundReplayTw}>
          <DoubleSvgImage
            tw="w-16 h-16"
            aspectRatio="1:1"
            background={image.background}
            foreground={imageForeground?.upload}
            backgroundColor={image.backgroundColor}
          />
        </View>

        <View tw={textCardTw}>
          <Text tw="font-serif text-sm font-semibold md:text-base leading-snug mb-2 text-gray-900" numberOfLines={2}>{title}</Text>
          <Text tw="font-sans font-normal text-xs md:text-sm leading-relaxed text-gray-700">{dateFormatted}</Text>
        </View>
      </View>
    </Pressable>
  )
}
