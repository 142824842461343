import { useLinkTo } from '@react-navigation/native'
import { differenceInSeconds, Duration, fromUnixTime } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'

import {
  getYear, Interval, intervalToDuration, isBefore, isWithinInterval,
} from '~/utils/dateFns/common.helpers'

export const useNow = (refreshPeriod = 60000): Timestamp => {
  const [now, setNow] = useState(Timestamp.now())

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('Will refresh timer "now"...')
      setNow(Timestamp.now())
    }, refreshPeriod)

    return () => clearInterval(interval)
  }, [refreshPeriod])

  return now
}

export const useThisYear = (refreshPeriod = 600000): Timestamp => {
  const [thisYear, setThisYear] = useState(getYear(Timestamp.now()))

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('Will refresh timer "now"...')
      setThisYear(getYear(Timestamp.now()))
    }, refreshPeriod)

    return () => clearInterval(interval)
  }, [refreshPeriod])

  return thisYear
}

const getTimeToUnix = (input) => differenceInSeconds(fromUnixTime(input), new Date())

/**
 * Unix timestamps in sec
 */

export const useSecondsTo = (target : number|undefined, refreshPeriod = 1000): number|undefined => {

  const [timer, setTimer] = useState<number>()

  useEffect(() => {
    if (target) {
      const interval = setInterval(() => {
        // console.log('Will refresh count down...')
        const diff = getTimeToUnix(target)
        if (diff >= 0) {
          setTimer(getTimeToUnix(target))
        } else {
          clearInterval(interval)
        }
      }, refreshPeriod)

      return () => clearInterval(interval)
    }

    return () => ({})

  }, [refreshPeriod, target])

  return timer
}

export const useTimeout = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setTimeout(() => savedCallback.current(), delay)

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(id)
    }
  }, [delay])
}

export const useDelayedFn = (delay:number) => {
  const [delayed, setDelayed] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay)

    return () => clearTimeout(timeout)

    // Purposedly ignored delay
  }, [])

  return (fn) => !delayed && fn()
}

export const useRedirectAfterDelay = ({ to, delay }) => {

  const linkTo = useLinkTo()
  const [delayed, setDelayed] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay)

    return () => clearTimeout(timeout)

    // Purposedly ignored delay
  }, [])

  useEffect(() => {
    linkTo(to)
  }, [delayed, linkTo, to])

}

/**
 * Returns if the input timestamp effectively happened in the past or not
 */
export const useIsPast = (input:Timestamp, refreshPeriod = 60000): boolean => {
  const [now, setNow] = useState(Timestamp.now())

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('Will refresh timer "now"...')
      setNow(Timestamp.now())
    }, refreshPeriod)

    return () => clearInterval(interval)
  }, [refreshPeriod])

  return isBefore(input, now)
}

/**
 * returns a duration object representing interval between now and a target date
 */
export const useDurationToTimestamp = (input:Timestamp, refreshPeriod = 60000): Duration & {isFuture:boolean, isPast:boolean} => {
  const [now, setNow] = useState(Timestamp.now())
  let start = input
  if (input === undefined) {
    start = now
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('Will refresh timer "now"...')
      setNow(Timestamp.now())
    }, refreshPeriod)

    return () => clearInterval(interval)
  }, [refreshPeriod])

  return intervalToDuration({ start, end: now })
}

/**
 * returns a boolean according to if input is placed within a specific time interval, or not
 */
export const useIsWithinInterval = (input:Date, interval: Interval, refreshPeriod = 60000) => {
  const [now, setNow] = useState(new Date())
  let moment = input
  if (input === undefined) {
    moment = now
  }

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      setNow(new Date())
    }, refreshPeriod)

    return () => clearInterval(refreshInterval)
  }, [refreshPeriod])

  return isWithinInterval(moment, interval)
}
