// import { Slider as RnSlider, SliderProps as RnSliderProps } from 'react-native-elements'
import RnSlider, { SliderProps as RnSliderProps } from '@react-native-community/slider'
import { FC } from 'react'

import { tailwind } from '~/theme/tailwind'

export type SliderProps = {
  tw?:string
} & Omit<RnSliderProps, 'style'>

/**
 * See
 * https://reactnativeelements.com/docs/Slider
 */
export const Slider: FC<SliderProps> = ({ tw, ...props }) => <RnSlider {...props} style={tailwind(tw || '')} />
