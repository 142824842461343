import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import ListItem from '~/elements/list/ListItem'
import Overlay from '~/elements/overlays/Overlay'
import { useAllEnabledEvaluations } from '~/hooks/dato.evaluations'
import { useCurrentUserAllEvaluations, useGetCurrentUserLatestEvaluationByType } from '~/hooks/firestore.evaluations'
import { EvaluationsStackParamList } from '~/navigators/EvaluationsStackParamList'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { UserPersonalitySection } from './UserPersonalitySection'

export type MyEvaluationsScreenProps = StackScreenProps<EvaluationsStackParamList, 'MyEvaluations'>

export const MyEvaluationsScreen: FC<MyEvaluationsScreenProps> = () => (
  <MainContainer scrollEnabled contentContainerTw="flex-col items-start w-full">
    <UserPersonalitySection />
    {/* <OtherEvaluationsSection /> */}
  </MainContainer>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TestSnippet = ({ slug, index, size }) => {
  // console.log('-> TestSnippet', slug, index, size)
  const { isLoading, error, data } = useGetCurrentUserLatestEvaluationByType(slug)

  if (isLoading) {
    return <LoadingScreen />
  }
  if (error) {
    return <ErrorScreen error={error} />
  }

  // console.log('data', data)

  if (!data) {
    return null
  }// <Text>{ `No ${slug} yet.`}</Text>

  const isFirst = index === 0
  const isLast = index === size - 1

  // console.log(`isFirst`, isFirst)
  // console.log(`isLast`, isLast)

  return (
    <ListItem
      key={data.id}
      bottomDivider
      to={`/evaluations/${data.id}`}
      isFirst={isFirst}
      isLast={isLast}
      >

      <Icon name="house" size="3x" />
      {/* <ListItem.Content> */}
      {/* <ListItem.Title>{data.get('slug')}</ListItem.Title> */}
      {/* <ListItem.Subtitle>{format(data.get('date').toDate(), 'dd MMM yyyy')}</ListItem.Subtitle> */}
      {/* </ListItem.Content> */}
      {/* <ListChevron /> */}
    </ListItem>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OtherEvaluationsSection = () => {
  const linkTo = useLinkTo()

  // We get list of Evaluations to display from Dato CMS
  const allEnabledFormsRes = useAllEnabledEvaluations()
  // console.log(`allEnabledFormsRes`, allEnabledFormsRes)

  // We compare with all evaluations current user has already taken
  const currentUserEvaluationsRes = useCurrentUserAllEvaluations()
  // console.log(`currentUserEvaluationsRes`, currentUserEvaluationsRes)

  const [isOverlayVisible, setOverlayVisible] = useState(false)

  const { t } = useTranslation(['my-evaluations'])

  if (allEnabledFormsRes.isLoading || currentUserEvaluationsRes.isLoading) {
    return <LoadingScreen />
  }
  if (allEnabledFormsRes.error || currentUserEvaluationsRes.error) {
    return <ErrorScreen error={allEnabledFormsRes.error || currentUserEvaluationsRes.error} />
  }

  const enabledSlugs = allEnabledFormsRes.data.allEvaluations.map((item) => item.slug)
  // console.log('enabledSlugs', enabledSlugs)
  // const enabledEvaluations = currentUserEvaluationsRes.data.filter((doc) => enabledSlugs.includes(doc.get('slug')))
  // console.log(`enabledEvaluations`, enabledEvaluations.length)

  // if (enabledEvaluations.length === 0) return <MyEvaluationsEmptyScreen />

  // const foo = enabledSlugs.filter((key) => key !== 'user-personalities')

  return (
    <>
      <BasicSectionContainer tw="">
        {/* <SectionHeading title="Retrouvez vos autres tests" />
        {foo.map((slug, index) => <TestSnippet key={slug} slug={slug} index={index} size={foo.length} />)} */}

        {/* <Divider /> */}
        <View tw="mt-12">
          <Button
            color="brand"
            title={t('main.new_evaluation')}
            onPress={() => setOverlayVisible(true)}
          />
        </View>
      </BasicSectionContainer>
      <Overlay
        onBackdropPress={() => setOverlayVisible(false)}
        isVisible={isOverlayVisible}
        >
        <Overlay.Title>{t('main.overlay.title')}</Overlay.Title>
        <View tw="flex-col flex-grow justify-center">
          {enabledSlugs.map((slug) => (
            <Button
              tw="mt-2"
              variant="outline"
              key={slug}
              title={allEnabledFormsRes.data?.allEvaluations.find((item) => item.slug === slug)?.title}
              onPress={() => {
                setOverlayVisible(false)
                linkTo(`/evaluations/${slug}/new`)
              }}
            />
          ))}
        </View>
      </Overlay>
    </>
  )
}
