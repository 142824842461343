import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { SearchContentBar } from '~/screens/ExploreContentScreen/SearchContentBar'
import { prefixMatch } from '~/theme/tailwind'

import { CategoriesSection } from './CategoriesSection'
import { sections } from './content-sections'
import { ContentSection } from './ContentSection'

/**
 * A modular screen presents a search bar with content initially.
 * - On Search bar focus, it shows suggested searches and hides initial content
 * - On search executed it shows search results
 */
export const ExploreContentScreen = () => {
  // console.log('-> Search screen')

  const [isSearchActive, setSearchActive] = useState(false)
  const makeSearchActive = () => setSearchActive(true)
  const makeSearchInactive = () => setSearchActive(false)

  const { t } = useTranslation(['library'])

  return (
    <MainContainer scrollEnabled contentContainerTw="flex-col items-start w-full">

      {!isSearchActive && prefixMatch('md') && <ScreenHeadingSection title={t('library:main.heading.title')} description={t('library:main.heading.description')} tw="mb-4" />}

      {prefixMatch('md') && (
        <SearchContentBar makeSearchActive={() => makeSearchActive()} makeSearchInactive={() => makeSearchInactive()} isSearchActive={isSearchActive} />
      )}

      {!isSearchActive && (
        <>
          <ContentSection sections={sections} />
          <CategoriesSection />
        </>
      )}

    </MainContainer>
  )
}
