import { yupResolver } from '@hookform/resolvers/yup'
import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { httpsCallable } from 'firebase/functions'
import { FC, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import analytics from '~/analytics'
// import { CheckboxInput } from '~/components/forms/input-groups/CheckboxInput'
import { EmailInput } from '~/components/forms/input-groups/EmailInput'
import { AuthLayout } from '~/components/layouts/containers/AuthLayout'
import { UserInfo } from '~/definitions/firebase.auth.types'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { functionsEU } from '~/navigators.shared/app.firebase.client'
import { NonAuthStackParamList } from '~/navigators/NonAuthStackParamList.d'
import { prefixMatch } from '~/theme/tailwind'

type TitleType = 'auth:signup.main.headline' | 'auth:signup.main.headlineError'
type DescriptionType = 'auth:signup.main.intro' | 'auth:signup.main.introError'

export type SignUpScreenProps = StackScreenProps<NonAuthStackParamList, 'SignUp'>

/**
 * Historically, used to be done on the web hence "pre"
 */
const SignUpScreen: FC<SignUpScreenProps> = ({ route }) => {
  const {
    i18n: {
      language,
    },
    t,
  } = useTranslation(['auth', 'forms', 'onboarding'])

  const {
    params: {
      email: emailFromParams,
    } = {},
  } = route

  const [title, setTitle] = useState<TitleType>('auth:signup.main.headline')
  const [description, setDescription] = useState<DescriptionType>('auth:signup.main.intro')

  const schema = Yup.object().shape({
    email: Yup.string().email(t('forms:email.invalid')).required(t('forms:string.required')),
    // healthDataCollectionConsent: Yup.boolean().required('The terms and conditions must be accepted.')
    //   .oneOf([true], 'The terms and conditions must be accepted.'),
  })

  const email = emailFromParams ? decodeURIComponent(emailFromParams) : ''

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: { email, password: '' },
    resolver: yupResolver(schema),
  })

  const linkTo = useLinkTo()

  // console.log(`i18n`, i18n)

  const handleContactUs = () => {
    // console.log('redirect to contact us webview')
  }

  const registerUserFn = httpsCallable(functionsEU, 'httpRegisterUser')

  const handlePreRegister = async (values: FieldValues) => {
    // console.log('Data submitted', values)

    const {
      email: emailFromForm,
      // healthDataCollectionConsent,
    } = values

    const encodedEmail = encodeURIComponent(emailFromForm)

    try {
      // We tentatively register a new user
      const registerUserPayload = {
        email: emailFromForm,
        // healthDataCollectionConsent,
        settings: {
          language,
        },
      }

      // console.log('registerUserPayload', registerUserPayload)
      const { data } = await registerUserFn(registerUserPayload)
      // console.log('Succes')

      const {
        uid: userId,
        email: userEmail,
      } = data as UserInfo

      // Success! We log that to Segment
      analytics.identify({ userId })
      analytics.track('new-user', { email: userEmail })

      // we redirect to the Magic link page
      linkTo(`/auth/link?email=${encodedEmail}&origin=new-user`)

    } catch (error) {
      console.error(error)
      const {
        message,
        details: {
          code: codeFromErrorDetails,
        } = {},
      } = error

      switch (codeFromErrorDetails) {

      case 'EMAIL_ALREADY_USED': {
        // Email already used => Not allowed => redirect to NotAllowed screen to sign in with other account
        linkTo(`/auth/forbidden?email=${encodedEmail}&origin=email-already-used`)
        break
      }

      case 'CUSTOMER_CAMPAIGN_NOT_FOUND': {
        // No campaign => Not allowed => redirect to NotAllowed screen
        linkTo(`/auth/forbidden?email=${encodedEmail}`)
        break
      }

      case 'auth/email-already-exists': {
        // directly signin user
        linkTo(`/auth/link?email=${encodedEmail}&origin=existing-user`)
        break
      }

      default: {
        setTitle('auth:signup.main.headlineError')
        setDescription('auth:signup.main.introError')
        throw new Error(`unhandled error :(${codeFromErrorDetails}) ${message}`)
      }
      }
    }

  }

  const canSubmit = Object.keys(methods.formState.errors).length === 0 // && values.healthDataCollectionConsent === true

  return (
    <AuthLayout title={t(title)} description={t(description)} showLanguageSelector>
      <FormProvider {...methods}>
        <EmailInput
          name="email"
          label={t('auth:signup.main.emailLabel')}
          align={prefixMatch('lg') ? 'left' : 'center'}
          onEnterKeyPress={methods.handleSubmit(handlePreRegister)}
        />
        {/* <CheckboxInput
          name='healthDataCollectionConsent'
          label={t('auth:signupconsent.healthData')}
        /> */}
        <Button
          onPress={methods.handleSubmit(handlePreRegister)}
          title={t('auth:signup.main.primaryCta')}
          color="basic"
          disabled={!canSubmit}
          tw="mt-4"
        />
        <Button
          to="/auth/signin"
          title={t('auth:signup.main.secondaryCta')}
          variant="ghost"
        />
      </FormProvider>
      <View tw="mt-24">
        <Text category="h4">{ t('auth:signup.alternate.headline')}</Text>
        <Text>{ t('auth:signup.alternate.intro')}</Text>
        <Button
          title={t('auth:signup.alternate.cta')}
          onPress={handleContactUs}
          color="basic"
          variant="outline"
          tw="mt-4"
          block
          to="https://www.mindday.com/contact-us"
        />
      </View>

    </AuthLayout>
  )
}

export default SignUpScreen
