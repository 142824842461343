import { createStackNavigator } from '@react-navigation/stack'
import { Suspense, useCallback } from 'react'

import { GoBackHeaderButton } from '~/elements/buttons/headerButtons/GoBackHeaderButton'
// import { GoBackHeaderButton } from '~/elements/buttons/headerButtons/GoBackHeaderButton'
import { SettingsHeaderButton } from '~/elements/buttons/headerButtons/SettingsHeaderButton'
import { MainLayoutHeader } from '~/elements/headers/MainLayoutHeader'
import { ErrorBoundary } from '~/elements/misc/ErrorBoundary'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { HomeChatScreen } from '~/screens/HomeScreen/HomeChatScreen'
import { HomeScreen } from '~/screens/HomeScreen/HomeScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { prefixMatch } from '~/theme/tailwind'

import { HomeStackParamList } from './HomeStackParamList'

const HomeStack = createStackNavigator<HomeStackParamList>()

const ErrorFallback = (props) => <ErrorScreen {...props} />

export const HomeNavigator = () => {
  // console.log('-> HomeNavigator')
  const md = prefixMatch('md')

  const HeaderRight = useCallback(() => ((!md) ? <SettingsHeaderButton size="base" to="/preferences" /> : null), [md])
  const ChatHeader = useCallback(() => <GoBackHeaderButton label={false} to="/" />, [])

  return (
    <ErrorBoundary
      fallback={<ErrorFallback />}
      location="home"
      >
      <Suspense fallback={<LoadingScreen />}>
        <HomeStack.Navigator>
          <HomeStack.Screen
            name="Dashboard"
            component={HomeScreen}
            options={{
              headerShown: true,
              headerTransparent: true,
              headerRight: HeaderRight,
              header: MainLayoutHeader,
            }}
          />
          <HomeStack.Screen
            name="ChatStack"
            component={HomeChatScreen}
            options={{
              headerShown: true,
              headerTransparent: true,
              headerLeft: ChatHeader,
              header: MainLayoutHeader,
            }}
          />
        </HomeStack.Navigator>
      </Suspense>
    </ErrorBoundary>
  )
}
