import { useTranslation } from 'react-i18next'

import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'
import { useDatoSvg } from '~/hooks/dato.assets'

export const EmptyStateIndicator = () => {
  const { data } = useDatoSvg('42354374')
  const { t } = useTranslation(['chat'])
  const title = 'chat:conversation.empty.title'
  const description = 'chat:conversation.empty.description'
  return (
    <View tw="h-full max-w-xlp w-full px-4 pt-6">
      <Image tw="h-48" {...data?.upload} />
      <Text tw="mt-4 px-10 text-center text-xl font-serif font-semibold">{t(title)}</Text>
      <Text tw="mt-2 px-10 text-center text-gray-500">{t(description)}</Text>
    </View>
  )
}

export const EmptyStateIndicatorNotReady = () => {
  const { data } = useDatoSvg('42354374')
  const { t } = useTranslation(['chat'])
  const title = 'chat:conversation.empty.titleNotReady'
  const description = 'chat:conversation.empty.descriptionNotReady'
  return (
    <View tw="h-full max-w-xlp w-full px-4 pt-6">
      <Image tw="h-48" {...data?.upload} />
      <Text tw="mt-4 px-10 text-center text-xl font-serif font-semibold">{t(title)}</Text>
      <Text tw="mt-2 px-10 text-center text-gray-500">{t(description)}</Text>
    </View>
  )
}
