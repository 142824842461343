/**
 * A few constants that help responsiveness of the Content Player layout
 */

import { prefixMatch } from '~/theme/tailwind'

/**
 * margin on top of the main card, excluding header height
 */
export const cardTopMargin = 8

/**
 * Screen height threshold up to which card footer appears as bottom sticky
 */
export const noBottomPaddingUnder = 750

/**
 * Card maximum height beyond which it wont grow anymore
 */
export const cardMaxHeight = 1200

/**
 * When card has reached its maximum height, what is the expected bottom margin?
 * Any screen higher that the corresponding value will result in adding more margin exclusively
 */
export const bottomPaddingAtMaxLinearValue = 300

/**
 * Height of the transparent gradient that blend content at the bottom of a card
 */
export const bottomFaderHeightDesktop = 150
export const bottomFaderHeightMobile = 80

/**
 * Height of the nav bar at the bottom of a Content card
 */
export const cardFooterHeightDesktop = 64

export const cardFooterHeightMobile = 44

export const useCardFooterHeight = () => (prefixMatch('md') ? cardFooterHeightDesktop : cardFooterHeightMobile)

export const useBottomFaderHeight = () => (prefixMatch('md') ? bottomFaderHeightDesktop : bottomFaderHeightMobile)
