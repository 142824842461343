import { useAuthIdToken, useAuthUser } from '@react-query-firebase/auth'
import { differenceInMinutes } from 'date-fns'
import { signOut } from 'firebase/auth'
import { useEffect, useState } from 'react'

import analytics from '~/analytics'
import { roleList } from '~/constants/firebase.auth'
import { User } from '~/definitions/firebase.auth.types'

import { auth } from '../navigators.shared/app.firebase.client'
import { localStorageKeys, useAsyncStorage } from './storage'

// console.log('-> auth.ts')

/**
 * Listen for auth state changes
 */
export const useAuthStateChanged = () => {
  // console.log('-> useAuthStateChanged')

  const {
    value: lastSignInTime,
    setItem: setLastSignInTime,
    removeItem: removeLastSignInTime,
  } = useAsyncStorage(localStorageKeys.LAST_SIGN_IN_TIME)

  const { removeItem: removeUserEmail } = useAsyncStorage(localStorageKeys.EMAIL)

  const [user, setUser] = useState<User | null>(null)

  const userQuery = useAuthUser('user', auth)
  // const tokenResult = useAuthIdToken(["token"], auth);

  // update user state when auth state changes
  useEffect(() => {
    if (!user && userQuery.data) {
      setUser(userQuery.data)
    } else if (user && !userQuery.data) {
      setUser(null)
    }

  }, [userQuery.data, user])

  /**
   * Hook on Firebase Auth State Changed
   */
  useEffect(() => {

    const onUserAuthed = async () => {

      // console.log('-> authStateChanged with user', usr)
      // refresh token so that we get the latest claims
      // const token = tokenResult.data?.token

      // only log signin if new sign in older than 3 hours on the same computer to avoid reload signin

      // console.log('lastSignInTime', lastSignInTime, typeof lastSignInTime)

      /**
         * New signin beyond 3h implies new session starting
         */
      const diffInMin = differenceInMinutes(lastSignInTime, new Date())
      if (!lastSignInTime || -diffInMin > 180) {
        // console.log('Effective Sign in')
        analytics.identify({ userId: user.uid })
        analytics.track('login')
      }

      // update value
      await setLastSignInTime(new Date())
    }

    const onUserLoggedOut = () => {
      // console.log('-> onAuthStateChanged: signedOut')
      analytics.track('logout')
      // analytics.reset()
      removeLastSignInTime()
      removeUserEmail()
    }

    if (user) {
      onUserAuthed()

    } else {
      onUserLoggedOut()
    }

    // we only register the listener once hence the empty array
  }, [user])

}

export const logout = async () => signOut(auth)

/**
 * User roles are stored as custom claims in Firebase Auth
 * By convention they all start with hasRoleXXX
 */
export const useUserRoles = () => {
  const { data: user } = useAuthUser('user', auth)
  const { data: token } = useAuthIdToken(['token'], auth)

  const [isLoading, setIsLoading] = useState(false)
  const [roles, setRoles] = useState<any>()

  useEffect(() => {
    if (user && !roles && token) {

      const getRoles = async () => {
        setIsLoading(true)

        // transforms idToken into roles

        // console.log('idToken', token)

        const rolesFromClaims = Object
          .keys(token.claims)
          .filter((key) => key.startsWith('hasRole'))

        // console.log('rolesFromClaims', rolesFromClaims)

        // reidentify user with these custom traits
        const traits = roleList.reduce((acc, key) => ({
          ...acc,
          [key]: token.claims[key] || false,
        }), {})
        // console.log('reidentify user with custom traits', traits)
        analytics.identify({ userId: user.uid, traits })

        // update local state
        setRoles(rolesFromClaims)

        setIsLoading(false)
      }

      getRoles()

    }

    // Exclude roles on purposes
  }, [user, token])

  return { data: roles, isLoading }

}

export const useSigninCheck = () => {
  const query = useAuthUser('user', auth)

  return {
    ...query,
    data: query.status === 'success' ? !!query.data : null,
  }

}
