// import { Header, HeaderProps } from '@react-navigation/elements'
import classNames from 'classnames'
import { BlurTint } from 'expo-blur'
import { FC } from 'react'

import { View } from '~/elements/containers/View'
import { prefixMatch } from '~/theme/tailwind'

import { BlurView } from '../containers/BlurView'
import { SafeAreaView } from '../containers/SafeAreaView'
import { Header, HeaderProps } from './Header'

export type FullOverlayHeaderProps = HeaderProps & {
 tw?:string
 blur?:BlurTint
 padded: boolean // add extra padding horizontally
}

const EventuallyBlurView = ({ children, blur, ...props }) => {

  if (blur && prefixMatch('md')) {
    return <BlurView {...props} intensity={30} tint={blur}>{children}</BlurView>
  }

  return <View {...props}>{children}</View>
}

/**
 * A custom heder that respects the same layout as the FullOverlay grid layout
 */
export const FullOverlayHeader:FC<FullOverlayHeaderProps> = ({
  padded, tw, blur = false, setDimensions, ...props
}) => {
  // console.log('-> FullOverlayHeader', tw)

  const containerTw = classNames('absolute w-full self-center flex-row justify-center', tw)

  return (
    <EventuallyBlurView tw={containerTw} blur={blur}>
      <SafeAreaView
        onLayout={(event) => {
          const {
            width, height,
          } = event.nativeEvent.layout
          if (setDimensions) {
            setDimensions({ width, height })
          }
        }}
        tw={classNames('max-w-6xl', prefixMatch('md') && padded ? 'w-10/12' : 'w-full')}
        >
        <Header
          {...props}
        />
      </SafeAreaView>
    </EventuallyBlurView>
  )
}
