import { CommonActions, useLinkTo, useNavigation } from '@react-navigation/native'
import { httpsCallable } from 'firebase/functions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { useChatContext } from '~/components/chat/context/ChatContext'
import { CardHeading } from '~/components/headings/CardHeading'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { TextInput } from '~/elements/input/TextInput'
import { useUser } from '~/hooks/user.context'
import { functionsEU } from '~/navigators.shared/app.firebase.client'
import { shadowLightStyle } from '~/theme/shadows'

export const ChatNewConversationScreen = () => {

  const linkTo = useLinkTo()
  const navigation = useNavigation()
  const { client } = useChatContext()
  const { t, i18n } = useTranslation(['chat'])
  const { user } = useUser()

  // Set channel id only once so that we avoid recreating same conversation on HMR
  const [channelId, setChannelId] = useState(uuidv4())
  useEffect(() => {
    if (!channelId) {
      setChannelId(uuidv4())
    }
  }, [])

  // console.log('channels', channels)
  // console.log('channels[0].id', channels[0].id)
  const createNewConversation = async ({ name }) => {
    // console.log('-> createNewConversation', name)
    const newChannelData = {
      name,
      members: [user?.uid, `care-team-${i18n.language}`],
      category: 'careManagers',
      status: 'active',
      vendors: {
        missive: {
          conversationId: null, // necessary as the partial update wont create the missing root object
        },
      },
    }
    const newChannel = client.channel('messaging', channelId, newChannelData)

    const channelEvent = await newChannel.create()

    try {
      const data = {
        channelEvent,
      }
      await httpsCallable(functionsEU, 'httpCreateConversation')(data)
    } catch (error) {
      // silently fail for now...
      return
    }

    // Remove the current route from the stack so that a goBack will not redirect here
    navigation.dispatch((state) => {
      const routes = state.routes.filter((r) => r.name !== 'NewConversation')
      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1,
      })
    })

    // open new conversation
    linkTo(`/conversations/${newChannel.id}?title=${name}`)

  }

  const [inputText, setInputText] = useState('')

  return (
    <MainContainer tw="flex-col items-center md:items-start w-full">
      <BasicSectionContainer tw="px-4 flex-col h-full justify-center md:justify-start md:mt-20">
        <View tw="bg-surface-1 rounded-2xl py-4 px-8" style={shadowLightStyle}>
          <CardHeading title={t('chat:new.title')} />
          <TextInput
            testID="new-conversation-input"
            value={inputText}
            placeholder={t('chat:new.placeholder')}
            onChangeText={setInputText}
            containerTw="bg-transparent border-none mt-4 mb-12"
            inputTw="focus:border-none bg-surface-1 px-0 py-4"
            multiline
            returnKeyType="next"
          />
        </View>
        <View tw="flex flex-row justify-center mt-8">
          <Button color="brand" title={t('chat:new.cta')} onPress={() => createNewConversation({ name: inputText })} />
        </View>
      </BasicSectionContainer>
    </MainContainer>
  )
}
