import { FC } from 'react'
import { NativeSafeAreaViewProps, SafeAreaView as RnSafeAreaView } from 'react-native-safe-area-context'

import { tailwind } from '~/theme/tailwind'

type SafeAreaViewProps = {
tw?: string
} & NativeSafeAreaViewProps

/**
 *
 * Beware, default for SafeAreaProvider is to have flex:1
 */
export const SafeAreaView:FC<SafeAreaViewProps> = ({ tw = '', style, ...props }) => (<RnSafeAreaView {...props} style={{ ...tailwind(tw), ...style }} />)
