/* eslint-disable global-require */

// import { Integrations } from '@sentry/tracing'
import 'expo-dev-client'

import cleanDeep from 'clean-deep'
import { registerRootComponent } from 'expo'
// eslint-disable-next-line import/no-extraneous-dependencies
import { connectToDevTools } from 'react-devtools-core'
// import { Platform } from 'react-native'
import {
// APP_VERSION,
// ENV,
  SENTRY_ENDPOINT_ID, SENTRY_PROJECT_ID,
  SENTRY_PUBLIC_KEY,
} from 'react-native-dotenv'
import * as Sentry from 'sentry-expo'

import { AppBackgroundContextProvider } from '~/context/AppBackgroundContext'

import { AppWithBgContext } from './navigators/AppWithBgContext'

console.log('app starting...')

if (__DEV__) {
  connectToDevTools({
    host: 'localhost',
    port: 8097,
  })
}

if (SENTRY_PUBLIC_KEY) {
  const dsn = `https://${SENTRY_PUBLIC_KEY}@${SENTRY_ENDPOINT_ID}.ingest.sentry.io/${SENTRY_PROJECT_ID}`
  // console.log('will init Sentry', dsn)
  // const integrations = Platform.OS === 'web' ? [new Integrations.BrowserTracing()] : undefined
  const sentryConfig = cleanDeep({
    dsn,
    enableInExpoDevelopment: true,
    debug: __DEV__, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
    // integrations,
    // tracesSampleRate: 1.0,
    // environment: ENV,
    // release: `v${APP_VERSION}`,
  })
  // console.log('sentryConfig', sentryConfig)
  Sentry.init(sentryConfig)
}

// TODO: dark mode
// const colorScheme = useColorScheme()

export default registerRootComponent(() => (
  <AppBackgroundContextProvider>
    <AppWithBgContext />
  </AppBackgroundContextProvider>
))
