import { FC, memo } from 'react'

import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'

import { useTranslationContext } from '../context/TranslationContext'
import { SafeAnchor } from '../SafeAnchor/SafeAnchor'

export type CardProps = {
  /** The url of the full sized image */
  image_url?: string;
  /** The scraped url, used as a fallback if the OG-data doesn't include a link */
  og_scrape_url?: string;
  /** Description returned by the OG scraper */
  text?: string;
  /** The url for thumbnail sized image */
  thumb_url?: string;
  /** Title returned by the OG scraper */
  title?: string;
  /** Link returned by the OG scraper */
  title_link?: string;
  /** The card type used in the className attribute */
  // type?: string;
};

const UnMemoizedCard: FC<CardProps> = (props) => {
  const {
    image_url, og_scrape_url, text, thumb_url, title, title_link,
  } = props

  const { t } = useTranslationContext('Card')

  const image = thumb_url || image_url

  const trimUrl = (url?: string | null) => {
    // eslint-disable-next-line lodash/prefer-is-nil
    if (url !== undefined && url !== null) {
      const [trimmedUrl] = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')

      return trimmedUrl
    }
    return null
  }

  if (!title && !title_link && !image) {
    return (
      <View>
        <View>
          <Text>
            {t('this content could not be displayed')}
          </Text>
        </View>
      </View>
    )
  }

  if (!title_link && !og_scrape_url) {
    return null
  }

  return (
    <View>
      {image && (
        <View>
          <Image alt={image} url={image} />
        </View>
      )}
      <View>
        <View>
          {title && <Text>{title}</Text>}
          {text && <Text>{text}</Text>}
          {(title_link || og_scrape_url) && (
            <SafeAnchor
              href={title_link || og_scrape_url}
              rel="noopener noreferrer"
              target="_blank"
              >
              {trimUrl(title_link || og_scrape_url)}
            </SafeAnchor>
          )}
        </View>
      </View>
    </View>
  )
}

/**
 * Simple Card Layout for displaying links
 */
export const Card = memo(UnMemoizedCard) as typeof UnMemoizedCard
