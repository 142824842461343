import { BlurView as RnBlurView, BlurViewProps as RnBlurViewProps } from 'expo-blur'
import { FC } from 'react'

import { tailwind } from '~/theme/tailwind'

export type BlurViewProps = Omit<RnBlurViewProps, 'style'> & { tw?:string}

export const BlurView:FC<BlurViewProps> = ({ tw = '', style, ...props }) => {
  // console.log('-> BlurView', tw)
  const nextStyle = { ...tailwind(tw), ...style }
  // console.log('-> BlurView nextStyle', nextStyle)
  return <RnBlurView style={nextStyle} {...props} />
}
