import { FC, memo } from 'react'

import { View } from '~/elements/containers/View'

export type CustomNotificationProps = {
  // type: string;
  active?: boolean;
};

const UnMemoizedCustomNotification: FC<CustomNotificationProps> = (props) => {
  const { active, children } = props

  if (!active) {
    return null
  }

  return (
    <View
      data-testid="custom-notification"
      >
      {children}
    </View>
  )
}

export const CustomNotification = memo(
  UnMemoizedCustomNotification,
) as typeof UnMemoizedCustomNotification
