import { useHeaderHeight } from '@react-navigation/elements'
import {
  FC, useEffect, useRef, useState,
} from 'react'
import { useResponsiveHeight } from 'react-native-responsive-dimensions'

import {
  bottomPaddingAtMaxLinearValue,
  cardFooterHeightDesktop,
  cardFooterHeightMobile,
  cardMaxHeight, cardTopMargin, noBottomPaddingUnder, useBottomFaderHeight,
} from '~/constants/ContentPlayerContainer.constants'
import { getBgColor } from '~/elements/colors/colors.helpers'
import { ScrollView } from '~/elements/containers/ScrollView'
import { View } from '~/elements/containers/View'
import { BottomContentNavBar } from '~/screens/ContentPlayerScreen/BottomNavBar'
import { ContentPlayerProgressBar } from '~/screens/ContentPlayerScreen/ContentPlayerProgressBar'
import { prefixMatch } from '~/theme/tailwind'

type ContentPlayerScrollViewProps = {
  bgColor?: string
  shouldFadeContentAbove?: boolean
  shouldNavDisplay?: boolean

}

/**
 * Returns height between bottom of scrollView and bottom of the screen
 * @param height - screen height
 * @param headerHeight - header height
 * @returns
 */
const getFooterHeight = (height: number, headerHeight: number): number => {

  const md = prefixMatch('md')

  const cardFooterHeight = md ? cardFooterHeightDesktop : cardFooterHeightMobile

  // Footer is minimum when screen height < 600
  if (height < noBottomPaddingUnder) {
    // console.log('case under minimum')

    return cardFooterHeight
  }

  // Footer takes the whole space so that card height isnt higher than max card Size
  const maxLinearVal = headerHeight + cardTopMargin + cardMaxHeight + bottomPaddingAtMaxLinearValue
  if (height > maxLinearVal) {
    // console.log('case over threshold')

    return height - (headerHeight + cardTopMargin + cardMaxHeight - cardFooterHeight)
  }

  // in between is a linear value
  // console.log('case in between')

  return ((height - noBottomPaddingUnder) / (maxLinearVal - noBottomPaddingUnder)) * bottomPaddingAtMaxLinearValue + cardFooterHeight
}

/**
 * A responsive layout for our Content Player
 * - Player navigation appears to be bottom sticky up to a certain point
 * - Over a certain point, Card wont expand further and all additionnal space will be passed as bottom padding
 * - in between, we grow a linear bottom padding up to a point when we've decided main card shouldnt grow anymore
 *
 * We offer a consistant layout with navigation buttons being always at the same screen height
 */
export const ContentPlayerScrollView: FC<ContentPlayerScrollViewProps> = ({
  bgColor = 'surface-1', shouldFadeContentAbove = true, shouldNavDisplay = true, children,
}) => {

  const instantHeaderHeight = useHeaderHeight()
  const bottomFaderHeight = useBottomFaderHeight()
  const paddingBottom = shouldFadeContentAbove ? bottomFaderHeight : 0

  const height = useResponsiveHeight(75)

  // console.log('screen height', Math.round(height))

  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    if (instantHeaderHeight > 0 && instantHeaderHeight !== headerHeight) {
      setHeaderHeight(instantHeaderHeight)
    }
  }, [instantHeaderHeight, headerHeight])
  // console.log('header height', Math.round(headerHeight))

  // Scroll to top at each content page change
  const scrollRef = useRef(null)
  scrollRef.current?.scrollTo({ y: 0, animated: true })

  const footerHeight = getFooterHeight(height, headerHeight)
  // console.log('footerHeight', Math.round(footerHeight))

  // const visibleCardHeight = height - headerHeight - cardTopMargin - footerHeight + cardFooterHeight
  // console.log('visibleCardHeight', visibleCardHeight)
  // console.log('padding bottom', footerHeight - cardFooterHeight)

  const contentContainerMinHeight = height - headerHeight - footerHeight

  // console.log('contentContainerMinHeight', Math.round(contentContainerMinHeight))

  const cardContainerTw = `${getBgColor(bgColor)} md:bg-transparentrounded-t-xl w-full`

  const newCardTopMargin = cardTopMargin + (prefixMatch('md') ? headerHeight : 0)

  return (
    <View tw="flex-1 items-center">
      <View tw="flex-1 max-w-xlp w-full px-2 sm:px-6 md:px-0 md:mt-12">
        <ScrollView ref={scrollRef} tw="w-full " style={{ height: contentContainerMinHeight }}>
          {/* on larger screens. Progress bar belong to the scren header */}
          {!prefixMatch('md') && <ContentPlayerProgressBar style={{ height: headerHeight }} />}

          {/* Content card */}
          <View testID="content-card" tw={cardContainerTw} style={{ marginTop: newCardTopMargin, minHeight: contentContainerMinHeight, paddingBottom }}>
            {children}
          </View>

        </ScrollView>
        {shouldNavDisplay && (<BottomContentNavBar height={footerHeight} bgColor={bgColor} shouldFadeContentAbove={shouldFadeContentAbove} />)}
      </View>
    </View>
  )
}
