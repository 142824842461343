import classNames from 'classnames'
import { FC } from 'react'
import { ViewStyle } from 'react-native'

import { View } from '~/elements/containers/View'

type SingleColumnSectionContainerProps = {
  tw?: string
  style?: ViewStyle
}

/**
 * Basic container for single column sections.
 * It takes full width and has a padding on mobile; extends up to max-w-xlp above
 */
export const BasicSectionContainer:FC<SingleColumnSectionContainerProps> = ({ tw, style, children }) => (
  <View
    tw={classNames('max-w-xlp w-full px-4 md:px-8', tw)}
    style={style}
    >
    {children}
  </View>
)

/**
 * A container designed for carousels on a single column
 * Full-width on mobile, constrained with padded content above
 * TODO: on large screen that should add blending gradients on the edges (check Revolut for benchmark)
 */
export const CarouselSectionContainer:FC<SingleColumnSectionContainerProps> = ({ tw, style, children }) => (
  <View
    tw={classNames('max-w-xlp w-full px-4 md:px-8', tw)}
    style={style}
    >
    {children}
  </View>
)
