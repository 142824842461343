import classNames from 'classnames'
import { LinearGradient } from 'expo-linear-gradient'
import upperFirst from 'lodash/upperFirst'
import { FC, useRef } from 'react'
import { useActive, useFocus, useHover } from 'react-native-web-hooks'

import { tailwind } from '~/theme/tailwind'
import { getMarginRelatedClasses, getPositionRelatedClasses } from '~/utils/tailwind/tw.containers.helpers'
import {
  getGradientDirection, getGradientEndColor, getGradientRelatedClasses, getGradientStartColor,
} from '~/utils/tailwind/tw.gradients.helpers'
import { getRoundedTw } from '~/utils/tailwind/tw.images.helpers'
import { getTextRelatedClasses } from '~/utils/tailwind/tw.texts.helpers'

import { EventuallyPressable } from '../containers/EventuallyPressable'
import { getLinearGradientStartEndPoint } from '../containers/gradient.helpers'
import { View } from '../containers/View'
import { Icon } from '../icons/Icon'
import { Text } from '../text/Text'
import { TwButtonProps } from './Button.d'

/**
 * On web, only provides an angle, not really a start end position
 */

export const GradientButton: FC<TwButtonProps> = (props) => {
  // console.log('GradientButton')
  const {
    title, tw, hookKey, icon, iconRight, ...rest
  } = props

  const ref = useRef()
  const hover = useHover(ref)
  const focus = useFocus(ref)
  const active = useActive(ref)

  const interactionState = { active, hover, focus }
  // console.log('interactionState', interactionState)

  const { tw: iconFullTw, ...restIcon } = icon

  // Split tw classes into text vs container classes
  const { textTw: titleTw, nonTextTw: containerTw } = getTextRelatedClasses(tw)
  // console.log('textTw', titleTw)
  // console.log('nonTextTw', containerTw)
  // Idem for icon classes
  const { textTw: iconTw, nonTextTw: iconContainerTw } = getTextRelatedClasses(iconFullTw)

  // We convert gradient classes into a set of props for LinearGradient component
  const { gradientTw, nonGradientTw } = getGradientRelatedClasses(containerTw)

  const { positionTw, nonPositionTw } = getPositionRelatedClasses(nonGradientTw)
  // console.log('gradientTw', gradientTw)
  // console.log('nonPositionTw', nonPositionTw)

  const gradientDirection = getGradientDirection(gradientTw)
  // console.log('gradientDirection', gradientDirection)
  const { startPoint, endPoint } = getLinearGradientStartEndPoint(gradientDirection, 0.3, 0)
  // console.log('startPoint', startPoint)
  // console.log('endPoint', endPoint)

  const startColor = getGradientStartColor(gradientTw, interactionState)
  const endColor = getGradientEndColor(gradientTw, interactionState)
  // console.log('startColor', startColor)
  // console.log('endColor', endColor)

  const { result: roundingTw } = getRoundedTw(nonPositionTw)
  // console.log('roundingTw', roundingTw)

  const { marginTw, nonMarginTw: finalTw } = getMarginRelatedClasses(nonPositionTw)

  return (
    <EventuallyPressable
      hookKey={hookKey}
      {...rest}
      tw={classNames(positionTw, roundingTw, marginTw, 'overflow-hidden')}
      >
      <LinearGradient
        ref={ref}
        style={tailwind(finalTw)}
        colors={[startColor, endColor]}
        locations={[0.1, 0.9]} // restrict gradient to in-between these location values
        start={startPoint}
        end={endPoint}
        >
        {icon.name && !iconRight && <View tw={iconContainerTw}><Icon tw={iconTw} {...restIcon} /></View>}
        <Text tw={titleTw}>{upperFirst(title)}</Text>
        {icon.name && iconRight && <View tw={iconContainerTw}><Icon tw={iconTw} {...restIcon} /></View>}
      </LinearGradient>
    </EventuallyPressable>
  )
}
