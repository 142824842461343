import classNames from 'classnames'
import { FC, ReactElement, useCallback } from 'react'
import { ViewStyle } from 'react-native'

import { View } from '~/elements/containers/View'
import { prefixMatch } from '~/theme/tailwind'

import { BasicSectionContainer } from './SectionContainer'

type TwoColGridSectionProps = {
  tw?: string
  style?: ViewStyle
  Secondary?: ReactElement | null
  reverse?:boolean
  padded?:boolean
  // secondaryMaxWidth?: 'md' | 'sm',
  secondaryJustify?: string
}

/**
 * A container that displays a second column on large screens.
 * Typically used on wizards flow or carousels.
 */
export const TwoColGridSection: FC<TwoColGridSectionProps> = ({
  tw, style, Secondary, children, reverse = false, padded = false, secondaryJustify = 'justify-center',
}) => {

  const SecondaryColumn = useCallback(() => {
    if (!Secondary) {
      return null
    }
    return Secondary
  }, [Secondary])

  // On smaller screen, return the default single column section
  if (!prefixMatch('md')) {
    return <BasicSectionContainer tw={classNames('px-4', tw)} style={style}>{children}</BasicSectionContainer>
    // tw={classNames('px-4', tw)} is useless because BSC already has px-4, but maintaining here somehow limits side effects
  }

  const flexDirectionTw = reverse ? 'flex-row-reverse' : 'flex-row'
  const justifyTw = Secondary ? 'justify-between' : 'justify-center'
  const containerTw = classNames('w-full', flexDirectionTw, justifyTw, tw)
  const primaryColumnTw = `w-5/12 flex-col justify-center ${Secondary ? 'pl-4 xl:pl-0' : 'pl-0'}`
  const secondaryColumnTw = `w-${padded ? 4 : 5}/12 flex-col ${secondaryJustify}`

  // center first column if there is no second column
  if (!Secondary) {
    return (
      <View tw={containerTw} style={style}>
        <View tw={primaryColumnTw} style={style}>{children}</View>
      </View>
    )
  }

  return (
    <View tw={containerTw} style={style}>
      {padded && <View tw="w-1/12" />}
      <View tw={primaryColumnTw} style={style}>{children}</View>
      <View tw={`w-${padded ? 1 : 2}/12`} />
      <View tw={secondaryColumnTw} style={style}><SecondaryColumn /></View>
      {padded && <View tw="w-1/12" />}
    </View>
  )
}
