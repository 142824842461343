import { HeaderOptions } from '@react-navigation/elements'
import classNames from 'classnames'
import { FC } from 'react'

import { View } from '../containers/View'
import { Text } from '../text/Text'

export type HeaderProps = HeaderOptions & {
  /**
   * Whether the header is in a modal
   */
  // modal?: boolean;
  /**
   * Layout of the screen.
   */
  // layout?: Layout;
  /**
   * Title text for the header.
   */
  title: string;

  /**
   * Additionnal style
   */
   tw?:string
};

/**
 * TODO: come back to Native one on native platforms
 */
export const Header: FC<HeaderProps> = ({ tw, options }) => {

  const {
    headerLeft, title, headerRight, headerTitle,
  } = options

  return (
    <View tw={classNames('flex-row h-16 justify-between items-center', tw)}>
      <View tw="flex-row min-w-8 flex-none justify-start items-center">
        {!!headerLeft && headerLeft()}
      </View>
      <View tw="flex-col h-full flex-grow justify-center">
        {headerTitle ? headerTitle() : <Text>{title}</Text>}
      </View>
      <View tw="flex-row min-w-8 flex-none justify-end items-center">
        {!!headerRight && headerRight()}
      </View>
    </View>
  )
}
