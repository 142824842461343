import { ButtonSize } from '~/elements/buttons/Button.d'
import { prefixMatch } from '~/theme/tailwind'

export const useBtnSizeFromVertBp = (): ButtonSize => {

  if (prefixMatch('mdv')) {
    return 'base'
  }
  if (prefixMatch('smv')) {
    return 'sm'
  }
  return 'xs'
}
