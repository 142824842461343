import { ScrollView as RnScrollView, ScrollViewProps as RnScrollViewProps } from 'dripsy'
import { FC, forwardRef } from 'react'

import { tailwind } from '~/theme/tailwind'

export type ScrollViewProps = {
  tw?: string
  contentContainerTw?: string
} & RnScrollViewProps

export const ScrollView: FC<ScrollViewProps> = forwardRef(({
  tw, contentContainerTw, style, contentContainerStyle, ...props
}, ref) => (
  <RnScrollView
    {...props}
    style={{ ...tailwind(tw), ...style }}
    contentContainerStyle={{ ...tailwind(contentContainerTw), ...contentContainerStyle }}
    showsVerticalScrollIndicator={false}
    ref={ref}
  />
))
