import format from 'date-fns/format'
import upperFirst from 'lodash/upperFirst'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { MinderMobileCard } from '~/components/panels/cards/MinderMobileCard'
import { View } from '~/elements/containers/View'
import { ActivityIndicator } from '~/elements/progress/ActivityIndicator'
import { Text } from '~/elements/text/Text'
import { useMinderBySlug } from '~/hooks/dato.minders'
import { useMeetingByCalendlyId } from '~/hooks/firestore.meetings'
import { useRedirectAfterDelay } from '~/hooks/time'
import { getFullName } from '~/utils/people.helpers'

type SchedulingConfirmationScreenProps = {
  calendlyMeetingId: string
}

const SchedulingConfirmationWithMeeting = ({ minder, date }) => {
  // console.log(`minder prop:`, minder)
  // console.log(`date prop:`, date)
  const { t } = useTranslation(['track'])
  const { isLoading, data } = useMinderBySlug(minder)
  if (isLoading || !data) {
    return null
  }

  // console.log(`minder data`, data)
  return (
    <View tw="px-4 md:px-8">
      <Text tw="mt-4 mb-2">
        {t('track:scheduling.confirm.meeting_info', {
          date,
          time: format(date, 'H:mm'),
          minderFullName: `${getFullName(data.minder)}`,
        })}
      </Text>

      <MinderMobileCard minder={data.minder} slug={minder} />
    </View>
  )
}

const AwaitingView = () => {
  const { t } = useTranslation(['track'])

  return (
    <>
      <Text tw="mt-4 mb-2">{t('track:scheduling.confirm.awaiting')}</Text>
      <ActivityIndicator />
    </>
  )
}

const DeniedView = () => {
  const { t } = useTranslation(['track'])
  useRedirectAfterDelay({ to: '/track', delay: 1000 })

  return (
    <>
      <Text tw="mt-4 mb-2 text-error-base">{t('track:scheduling.confirm.denied')}</Text>
      {/* <Button title={t('scheduling.confirm.cta_denied')} to="/track"/> */}
    </>
  )
}

const ConfirmedView = () => {
  const { t } = useTranslation(['track'])

  // Force navigating away so as to reset navigation stack
  useRedirectAfterDelay({ to: '/track', delay: 1000 })

  return (
    <>
      <Text tw="mt-4 mb-2">{t('track:scheduling.confirm.approved')}</Text>
      {/* <Button
        title={t('scheduling.confirm.cta_approved')}
        to="/track"
      /> */}
    </>
  )
}

type SchedulingConfirmationStatus = undefined | 'awaiting'|'approved'|'denied'

export const SchedulingConfirmationScreen:FC<SchedulingConfirmationScreenProps> = ({ calendlyMeetingId }) => {
  // console.log('-> SchedulingConfirmationScreen', calendlyMeetingId)

  // get meeting info from firestore

  const { t } = useTranslation(['track'])

  const [status, setStatus] = useState<SchedulingConfirmationStatus>()

  const { data } = useMeetingByCalendlyId(calendlyMeetingId)

  useEffect(() => {
    if (data) {
      const firebaseStatus = data.get('status')
      if (firebaseStatus === 'tentative') {
        setStatus('awaiting')
      }
      if (firebaseStatus === 'planned') {
        setStatus('approved')
      }
      if (firebaseStatus === 'denied') {
        setStatus('denied')
      }
    }
  }, [data])

  // console.log(`meeting data`, data?.data())

  return (
    <MainContainer tw="flex-col items-center md:items-start w-full max-w-xlp lg:max-w-xl">
      <View>
        <ScreenHeadingSection title={upperFirst(t('track:scheduling.confirm.ty'))} />
        {data?.get('minder') && <SchedulingConfirmationWithMeeting minder={data.get('minder')} date={data.get('start_time').toDate()} />}
      </View>
      <BasicSectionContainer tw="w-full flex-col justify-start content-center md:content-start">

        {(!status || status === 'awaiting') && <AwaitingView />}

        {status === 'denied' && <DeniedView />}

        {status === 'approved' && <ConfirmedView />}
      </BasicSectionContainer>
    </MainContainer>
  )
}
