/**
 * Should be
 * https://github.com/Flipkart/recyclerlistview
 */
import { FlatList as RnFlatList, FlatListProps as RnFlatListProps } from 'dripsy'
import { FC, forwardRef } from 'react'

import { tailwind } from '~/theme/tailwind'

export type FlatListProps<T> = Record<string, unknown> & RnFlatListProps<T>

// eslint-disable-next-line no-undef
export const FlatList: FC<FlatListProps<T>> = forwardRef(({ tw, ...props }, ref) => <RnFlatList ref={ref} style={tailwind(tw)} {...props} />)
