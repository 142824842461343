import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { prefixMatch } from '~/theme/tailwind'

import { BottomTabNavigator } from './BottomTabNavigator'
import { DrawerNavigator } from './DrawerNavigator'

export const MenuNavigator = () => {
  // console.log('-> MenuNavigator')

  useTranslation(['navigation'])

  return (
    <Suspense fallback={<LoadingScreen />}>
      {prefixMatch('md') ? <DrawerNavigator /> : <BottomTabNavigator />}
    </Suspense>
  )

}
