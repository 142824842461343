import { LinearGradient } from 'expo-linear-gradient'
import { useTranslation } from 'react-i18next'

import { MeetingData } from '~/definitions/firestore.meeting'
import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { Text } from '~/elements/text/Text'
import { useDateFormat, useDurationFormat } from '~/hooks/dateFns'
import { useSessionBySlug } from '~/hooks/dato.sessions'
import { tailwind } from '~/theme/tailwind'

import { DatePresenter } from '../../data-display/DatePresenter'
import { MinderMini } from '../../data-display/MinderMini'

type SessionCardProps = MeetingData & {
  meetingId: string,
}
export const SessionCard = (props: SessionCardProps) => {
  // console.log('-> SessionCard', props)
  const {
    start_time: startTime, minder, meetingId, type,
  } = props
  const { t, i18n } = useTranslation(['track'])

  const { data } = useSessionBySlug(type)
  const durationString = useDurationFormat({ minutes: data?.session?.duration || 30 })

  const time = useDateFormat(startTime, 'time', i18n.language)

  return (
    <LinearGradient
      colors={['rgba(253, 233, 190, 1)', 'rgba(255, 150, 90, 0.91)']} // mindDay special
      // locations={[0.3, 0.9]} // restrict gradient to in-between these location values
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 1 }}
      style={tailwind('mb-6 rounded-xl p-6')}
      >
      <Pressable tw="flex flex-col md:flex-row justify-between items-center" to={`/sessions/${meetingId}`}>
        <View tw="md:w-2/3 flex-row items-start">
          <DatePresenter date={startTime} tw="mr-2" textColor="gray-700" />
          <View tw=" ml-1">
            <Text tw="font-serif font-medium text-lg text-gray-700 mb-1">{ t('track:main.plannedTime') + time}</Text>
            <MinderMini minder={minder} textColor="gray-500" />
            <View tw="mt-1 flex-row items-center">
              <View tw="w-6">
                <Icon tw="text-gray-500" name="clock" variant="regular" />
              </View>
              <Text tw="ml-2 text-sm text-gray-500">{durationString}</Text>
            </View>
          </View>
        </View>
      </Pressable>
    </LinearGradient>
  )
}
