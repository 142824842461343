import classNames from 'classnames'
import { LinearGradient } from 'expo-linear-gradient'
import removeMarkdown from 'markdown-to-text'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EvaluationRecord } from '~/__generated__/graphql.queries'
import { BigNumberViz } from '~/components/data-display/viz/BigNumberViz'
import { CursorViz } from '~/components/data-display/viz/CursorViz'
import { Viz3dCursor } from '~/components/data-display/viz/Viz3dCursor'
import { bottomFaderHeight } from '~/constants/EvaluationPlayerContainer.constants'
import { DocumentSnapshot } from '~/definitions/firebase.firestore.types'
import { EvaluationData } from '~/definitions/firestore.evaluations'
import { Button } from '~/elements/buttons/Button'
import { getLinearGradientStartEndPoint } from '~/elements/containers/gradient.helpers'
import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import Overlay from '~/elements/overlays/Overlay'
import { ContentPageParagraph } from '~/elements/text/Player.FontTypes'
import { Text } from '~/elements/text/Text'
import { getHexColorFromTwColor } from '~/theme/helpers'
import { prefixMatch } from '~/theme/tailwind'

import { EvaluationPlayerDimensionDesktopScreen } from './Page.ScoreDimensionDesktopScreen'
import { EvaluationPlayerDimensionMobileScreen } from './Page.ScoreDimensionMobileScreen'
import { getRatio, scoreRules } from './segmentRules/userPersonality'

const { startPoint, endPoint } = getLinearGradientStartEndPoint('bg-gradient-to-t')

// All elements common to Desktop & Mobile Screen here

type DimensionCardProps = Pick<EvaluationRecord, 'title' | 'excerpt' | 'description' | 'illustration'>

const DimensionCard: FC<DimensionCardProps> = ({
  title, excerpt, description, illustration,
}) => {
  const [isDetailsClicked, setIsDetailsClicked] = useState(false)
  const { t } = useTranslation(['common'])

  return (
    <>
      <Text tw="font-serif text-xl text-center">{title}</Text>
      <View tw="p-6 lg:z-20 lg:p-0 lg:py-6 lg:px-2">
        {excerpt && <ContentPageParagraph tw="text-center lg:text-sm" markdown={false}>{removeMarkdown(excerpt)}</ContentPageParagraph>}
        <Button title={t('common:cta.details')} variant="ghost" onPress={() => setIsDetailsClicked(true)} tw="uppercase text-orange-500" />
        <Overlay isVisible={isDetailsClicked} onBackdropPress={() => setIsDetailsClicked(false)}>
          <Overlay.Content scrollEnabled tw="p-14">
            <ContentPageParagraph tw="text-md max-w-none">{description}</ContentPageParagraph>
          </Overlay.Content>
          <Overlay.Footer>
            <LinearGradient
              colors={[getHexColorFromTwColor('surface-1'), getHexColorFromTwColor('surface-1'), 'rgba(255,255,255,0)']} // 'transparent' isnt rendered as white on Ssfari but black. See https://stackoverflow.com/questions/38391457/linear-gradient-to-transparent-bug-in-latest-safari
              style={{ height: bottomFaderHeight, width: '100%', marginTop: -bottomFaderHeight }}
              locations={[0, 0.05, 1]}
              start={startPoint}
              end={endPoint}
            />
          </Overlay.Footer>
        </Overlay>
      </View>
      {prefixMatch('lg') && illustration && <Image {...illustration} tw="w-4/5 absolute bottom-0" />}
      {/* in mobile layout, image is cut above */}
    </>
  )
}

const getCoupleFromArr = (array) => {
  const res = []
  for (let i = 0; i < array.length - 1; i += 1) {
    // This is where you'll capture that last value
    for (let j = i + 1; j < array.length; j += 1) {
      res.push([array[i], array[j]])
    }
  }
  return res
}

const VizResult = ({
  nbDimensions, linkedValues, evaluationDoc, visualisationMaxValue,
}) => {
  const { t } = useTranslation(['my-evaluations'])
  return (
    <>
      {nbDimensions === 1 && linkedValues.map((item) => {
        const value = evaluationDoc.get(`scores.${item.key}`)
        return <BigNumberViz key={item.key} eyebrow={t('my-evaluations:player.score')} value={value} maxValue={visualisationMaxValue} />
      })}
      {nbDimensions === 2 && (
        <CursorViz
          eyebrow={t('my-evaluations:player.score')}
          evaluationDoc={evaluationDoc}
          dim1={linkedValues[0]}
          dim2={linkedValues[1]}
          maxVal={visualisationMaxValue}
          getRatio={getRatio}
        />
      )}
      {nbDimensions === 3 && (
        <Viz3dCursor
          eyebrow={t('my-evaluations:player.score')}
          linkedValues={linkedValues}
          evaluationDoc={evaluationDoc}
          maxVal={visualisationMaxValue}
          getCoupleFromArr={getCoupleFromArr}
          getRatio={getRatio}
        />
      )}
    </>
  )
}

const Segment = ({ segments, segmentKey, active }) => {
  const { title: segmentName, explainer, description: segmentDesc } = segments.find((item) => item.key === segmentKey)
  const textColorTw = classNames('max-w-none', active ? 'text-gray-900' : 'text-gray-500')
  const titleTw = classNames(textColorTw, 'font-semibold font-serif')
  return (
    <>
      {segmentName && <Text tw={titleTw}>{segmentName}</Text>}
      {explainer && !segmentName && <Text tw={titleTw}>{explainer}</Text>}
      <ContentPageParagraph tw={textColorTw}>{segmentDesc}</ContentPageParagraph>
    </>
  )
}

const OtherScoresCard = ({ segments, inactiveSegments }) => {
  const [isOthersClicked, setIsOthersClicked] = useState(false)
  const { t } = useTranslation(['my-evaluations', 'common'])

  if (inactiveSegments.length > 0) {
    return (
      <View tw="mt-6 justify-start">
        <Button title={t('my-evaluations:player.cta_others')} variant="ghost" onPress={() => setIsOthersClicked(true)} tw="uppercase text-orange-700 pl-0 text-left font-normal" />
        <Overlay isVisible={isOthersClicked} onBackdropPress={() => setIsOthersClicked(false)}>
          <Overlay.Content scrollEnabled tw="p-14">
            <Text tw="text-orange-700 uppercase mb-4">{t('my-evaluations:player.others')}</Text>
            {inactiveSegments.map((key) => <Segment key={key} active={false} segmentKey={key} segments={segments} />)}
          </Overlay.Content>
          <Overlay.Footer>
            <LinearGradient
              colors={[getHexColorFromTwColor('surface-1'), getHexColorFromTwColor('surface-1'), 'rgba(255,255,255,0)']} // 'transparent' isnt rendered as white on Ssfari but black. See https://stackoverflow.com/questions/38391457/linear-gradient-to-transparent-bug-in-latest-safari
              style={{ height: bottomFaderHeight, width: '100%', marginTop: -bottomFaderHeight }}
              locations={[0, 0.05, 1]}
              start={startPoint}
              end={endPoint}
            />
          </Overlay.Footer>
        </Overlay>
      </View>
    )
  }
  return null
}

const ResultCard = ({
  nbDimensions, linkedValues, evaluationDoc, visualisationMaxValue, segments, activeSegments, inactiveSegments,
}) => (
  <>
    <VizResult nbDimensions={nbDimensions} linkedValues={linkedValues} evaluationDoc={evaluationDoc} visualisationMaxValue={visualisationMaxValue} />
    <View tw="mt-4">
      {activeSegments.map((key) => <Segment key={key} active segmentKey={key} segments={segments} />)}
      <OtherScoresCard segments={segments} inactiveSegments={inactiveSegments} />
      {/* now that other scores are an overlay, it opens both on mobile and desktops */}
    </View>
  </>
)

type PartialGetEvaluationModelQuery_evaluation_dimensionScoring = 'title' | 'excerpt' | 'description' | 'linkedValues' | 'illustration' | 'segments' | 'visualisationMaxValue'
type EvaluationDimensionProps = Pick<EvaluationRecord, PartialGetEvaluationModelQuery_evaluation_dimensionScoring> & {
  evaluationSlug: string
  dimensionKey: string | null
  evaluationDoc: DocumentSnapshot<EvaluationData>
}

export const EvaluationPlayerDimensionScreen: FC<EvaluationDimensionProps> = ({
  evaluationSlug,
  dimensionKey,
  evaluationDoc,
  linkedValues,
  visualisationMaxValue,
  segments,
  title,
  excerpt,
  description,
  illustration,
  ...props
}) => {

  const { criteria } = scoreRules[evaluationSlug].find((item) => item.key === dimensionKey)
  const activeSegments = criteria.filter((c) => c.test(evaluationDoc.get('scores'), visualisationMaxValue)).map((r) => r.key)
  const inactiveSegments = criteria.filter((c) => !c.test(evaluationDoc.get('scores'), visualisationMaxValue)).map((r) => r.key)

  const nbDimensions = linkedValues.length

  const resultCard = (
    <ResultCard
      nbDimensions={nbDimensions}
      linkedValues={linkedValues}
      evaluationDoc={evaluationDoc}
      visualisationMaxValue={visualisationMaxValue}
      segments={segments}
      activeSegments={activeSegments}
      inactiveSegments={inactiveSegments}
    />
  )

  const dimensionCard = <DimensionCard title={title} excerpt={excerpt} description={description} illustration={illustration} />

  // finally just here differenciate between desktop & mobile screens score dimension
  if (prefixMatch('lg')) {
    return (
      <EvaluationPlayerDimensionDesktopScreen {...props} resultCard={resultCard} dimensionCard={dimensionCard} />
    )
  }
  return (
    <EvaluationPlayerDimensionMobileScreen {...props} resultCard={resultCard} dimensionCard={dimensionCard} illustration={illustration} />
  )
}
