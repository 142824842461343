import { FC } from 'react'
import { Dimensions } from 'react-native'

import { SafeAreaView } from '~/elements/containers/SafeAreaView'

type OverlayInnerContainerType = {
  size?: 'auto' | 'small' | 'medium' | 'large' | 'full'
}

export const OverlayInnerContainer:FC<OverlayInnerContainerType> = ({ size = 'auto', children }) => {
  // console.log('-> OverlayInnerContainer', size)

  const { height, width } = Dimensions.get('screen')
  // console.log('width/height', width, height)

  let tw = 'flex-col justify-between p-0'

  switch (size) {
  case 'auto':
    tw += ' w-96 h-168 md:w-96 md:h-168 lg:w-192 lg:h-168 xl:w-208 xl:h-168'
    break
  case 'small':
    tw += ' w-96 h-96'
    break
  case 'medium':
    tw += ' w-96 h-168'
    break
  case 'large':
    tw += ' w-192 h-128'
    break
  case 'full':
    tw += ' w-full h-full'
    break

  default:
    throw new Error(`unknown size ${size}`)
  }

  // console.log('OverlayInnerContainer', { size, tw })

  return <SafeAreaView tw={tw} style={{ maxHeight: height, maxWidth: width }}>{children}</SafeAreaView>
}
