import classNames from 'classnames'
// eslint-disable-next-line you-dont-need-lodash-underscore/is-nan
import isNaN from 'lodash/isNaN'
import {
  FC, useRef, useState,
} from 'react'
import { LayoutChangeEvent } from 'react-native'

import { IllustrationFormat } from '~/components/panels/tiles/ContentTileProps.d'
import { hasSpecifiedDimension, hasSpecifiedHeight, hasSpecifiedWidth } from '~/utils/tailwind/tw.containers.helpers'

import { getTwFromAspectRatio } from '../../utils/tailwind/tw.aspectRatio.helpers'
import { getBgColor } from '../colors/colors.helpers'
import { View } from '../containers/View'
import { getAspectRatioValue, getOrientationFromAspectRatio } from './aspectRatio.helpers'
import { AspectRatio, CardRounding } from './DoubleSvgImage/DoubleSvgImage.enums'
import { Image, ImageProps } from './Image'

/**
 * Standard object for a component that superposes a few elements into a responsive illustration
 */
export type Illustration = {
    backgroundColor: string
    background: ImageProps
    foreground: ImageProps
    format?: IllustrationFormat // should be used and queried systematically, but not the current reality of the code
  }

export type DoubleSvgImageProps = {
    aspectRatio :AspectRatio,
    rounded?: CardRounding
    tw?: string
    bgMode?: 'fill' | 'fit' // Fill will cover whole bg while fit will fit the bg image into the container
} & Illustration

/**
 * This component can takes as input dato CMS Illustration.
 * It superposes a background and a foreground image,
 * on top of a background color.
 *
 */
export const DoubleSvgImage:FC<DoubleSvgImageProps> = ({
  backgroundColor, background, foreground, aspectRatio, alt, rounded = 'none', tw = '', bgMode = 'fill',
}) => {

  const foregroundRef = useRef()

  let nextTw = tw

  if (!hasSpecifiedDimension(tw)) {
    nextTw += ' w-full'
  }

  // console.log('-> DoubleSvgImage')
  // console.log('background', background)
  // console.log('foreground', foreground)
  // console.log('aspectRatio', aspectRatio)
  // console.log('tw', tw)

  const orientation = getOrientationFromAspectRatio(aspectRatio)
  // console.log('orientation', orientation)

  const arValue = getAspectRatioValue(aspectRatio)
  // console.log('arValue', arValue)

  // reference dimension is height in landscape and width in portrait
  const refDim = orientation === 'landscape' ? 'height' : 'width'

  const [resizeRatio, setResizeRatio] = useState<number|undefined>()

  // We get the reduction factor from the ref dimension applied to the foreground
  const onForegroundResized = (e:LayoutChangeEvent) => {
    const newForegroundRefDim = e.nativeEvent.layout[refDim]
    // console.log(`resized ${refDim} :`, newForegroundRefDim)
    const resizeFactor = foreground[refDim] / newForegroundRefDim
    // console.log('resizeFactor', resizeFactor)
    setResizeRatio(resizeFactor)
  }

  if (!foreground) {
    return <Image {...background} tw={tw} />
  }

  if (!background && !foreground) {
    return null
  }

  if (!background && foreground) {
    return <Image {...foreground} tw={tw} />
  }

  const roundingTw = `rounded-${rounded}`

  const shouldFitFullDim = orientation === 'landscape' ? !hasSpecifiedHeight(nextTw) : !hasSpecifiedWidth(nextTw)
  // console.log('shouldFitFullDim', shouldFitFullDim)

  let fitFullTw = ''
  if (shouldFitFullDim) {
    fitFullTw = orientation === 'landscape' ? 'w-full' : 'h-full'
  }

  const containerTw = classNames(
    'relative overflow-hidden',
    getTwFromAspectRatio(aspectRatio),
    fitFullTw,
    getBgColor(backgroundColor),
    roundingTw,
    nextTw,
  )
  // console.log('containerTw', containerTw)

  // let containerStyle = {}
  // if (resizeRatio && foreground[refDim]) {
  //   const containerRefDim = foreground[refDim] / resizeRatio
  //   containerStyle = orientation === 'landscape' ? { height: containerRefDim } : { width: containerRefDim }
  // }
  // console.log('containerStyle', containerStyle)

  const backgroundTw = classNames(
    'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-100 rotate-0 skew-x-0 skew-y-0', // absolutely center image - // we add default so that TW does indeed generate global variables
    roundingTw,
  )
  // console.log('backgroundTw', backgroundTw)

  const bgResizeRatio = orientation === 'landscape' ? resizeRatio / (bgMode === 'fit' ? 1 : arValue) : resizeRatio * (bgMode === 'fit' ? 1 : arValue)
  // console.log('bgResizeRatio', bgResizeRatio)

  const bgWidth = Math.round((background?.width || 2048) / bgResizeRatio)
  const bgHeight = Math.round((background?.height || 2048) / bgResizeRatio)
  // console.log('resizeRatio', resizeRatio)
  // console.log('background', background)
  // console.log('foreground', foreground)
  // console.log('background dimensions', bgWidth, bgHeight)

  const foregroundContainerTw = classNames(
    getTwFromAspectRatio(aspectRatio),
    'flex-row justify-center items-center', // align image to center
    // orientation === 'landscape' ? 'w-full' : 'h-full',
    roundingTw,
  )
  // console.log('foregroundContainerTw', foregroundContainerTw)

  const foregroundTw = classNames(
    'object-center object-contain',
    roundingTw,
  )
  // console.log('foregroundTw', foregroundTw)

  const shouldDisplayBackground = !isNaN(resizeRatio) && !isNaN(bgHeight) && (!!background?.url)
  // console.log('shouldDisplayBackground', shouldDisplayBackground)

  /**
   * Foreground SVG dimensions are calculated from the container dimensions
   */
  // console.log('foreground', foreground)
  const foregroundWidth = Math.round((foreground?.foreground || 1024) / resizeRatio)
  const foregroundHeight = Math.round((foreground?.height || 1024) / resizeRatio)
  // console.log('foreground dimensions', foregroundWidth, foregroundHeight)

  const shouldDisplayForeground = !isNaN(foregroundWidth) && !isNaN(foregroundHeight) && (!!foreground?.url)
  // console.log('shouldDisplayForeground', shouldDisplayForeground)

  return (
    <View tw={containerTw}>
      {/* eslint-disable-next-line max-len */}
      {shouldDisplayBackground && <Image {...background} alt="" tw={backgroundTw} height={bgHeight} width={bgWidth} />}
      <View ref={foregroundRef} tw={foregroundContainerTw} onLayout={onForegroundResized}>
        {shouldDisplayForeground && <Image {...foreground} alt={alt} tw={foregroundTw} height={foregroundHeight} width={foregroundWidth} parentAspectRatio={aspectRatio} />}
      </View>
    </View>
  )
}
