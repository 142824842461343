import { LinearGradient } from 'expo-linear-gradient'
import { View } from 'react-native'

import { tailwind } from '~/theme/tailwind'

export const EventuallyGradientView = ({ focused, ...props }) => {
  if (focused) {
    return (
      <LinearGradient
        colors={['rgba(249, 173, 139, 1)', 'rgba(255,153,92,1)']} // gradient #3
        locations={[0.1, 0.9]} // restrict gradient to in-between these location values
        start={{ x: 0.3, y: 0.0 }}
        end={{ x: 0.7, y: 1.0 }}
        style={tailwind('z-20')}
        {...props}
      />
    )
  }

  return <View {...props} />
}
