import { useEffect } from 'react'

import analytics from '~/analytics'
import { useFormattedContentById } from '~/hooks/dato.content'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { prefixMatch } from '~/theme/tailwind'

import { ContentPlayerDataWrapper } from '../ContentPlayerDataWrapper'
import { ContentCoverDesktopScreen } from './ContentCoverDesktopView'
import { ContentCoverMobileScreen } from './ContentCoverMobileView'

const ContentPlayerCoverScreen = ({ route }) => {
  const { contentId } = route.params
  // console.log(`-> CoverPage for ${contentId}`)
  const { isLoading, error, data } = useFormattedContentById(contentId)

  useEffect(() => {
    analytics.track('playing-started', { itemId: contentId })
  }, [contentId])

  if (isLoading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <ContentPlayerDataWrapper contentId={contentId}>
      {prefixMatch('md') ? <ContentCoverDesktopScreen {...data} /> : <ContentCoverMobileScreen {...data} />}
    </ContentPlayerDataWrapper>
  )

}

export default ContentPlayerCoverScreen
