import {
  CSSProperties, FC, forwardRef, ForwardRefExoticComponent, MutableRefObject, RefAttributes,
} from 'react'
import { Image as DatoImage, ResponsiveImageType } from 'react-datocms'
import { Image as RnImage } from 'react-native'

import { tailwind } from '~/theme/tailwind'
import { getAspectRatioRelatedStyle } from '~/utils/tailwind/tw.aspectRatio.helpers'
import { getObjectFitPositionFromTw } from '~/utils/tailwind/tw.gatsbyImages.helpers'
import { getResizeMode } from '~/utils/tailwind/tw.resizeMode.helpers'

  declare type ImagePropTypes = {
    /** The actual response you get from a DatoCMS `responsiveImage` GraphQL query */
    data: ResponsiveImageType;
    /** Additional CSS className for root node */
    className?: string;
    /** Additional CSS class for the image inside the `<picture />` tag */
    pictureClassName?: string;
    /** Duration (in ms) of the fade-in transition effect upoad image loading */
    fadeInDuration?: number;
    /** @deprecated Use the intersectionThreshold prop */
    intersectionTreshold?: number;
    /** Indicate at what percentage of the placeholder visibility the loading of the image should be triggered. A value of 0 means that as soon as even one pixel is visible, the callback will be run. A value of 1.0 means that the threshold isn't considered passed until every pixel is visible */
    intersectionThreshold?: number;
    /** Margin around the placeholder. Can have values similar to the CSS margin property (top, right, bottom, left). The values can be percentages. This set of values serves to grow or shrink each side of the placeholder element's bounding box before computing intersections */
    intersectionMargin?: string;
    /** Whether enable lazy loading or not */
    lazyLoad?: boolean;
    /** Additional CSS rules to add to the root node */
    style?: CSSProperties;
    /** Additional CSS rules to add to the image inside the `<picture />` tag */
    pictureStyle?: CSSProperties;
    /**
     * The layout behavior of the image as the viewport changes size
     *
     * Possible values:
     *
     * * `intrinsic` (default): the image will scale the dimensions down for smaller viewports, but maintain the original dimensions for larger viewports
     * * `fixed`: the image dimensions will not change as the viewport changes (no responsiveness) similar to the native img element
     * * `responsive`: the image will scale the dimensions down for smaller viewports and scale up for larger viewports
     * * `fill`: image will stretch both width and height to the dimensions of the parent element, provided the parent element is `relative`
     * */
    layout?: 'intrinsic' | 'fixed' | 'responsive' | 'fill';
    /** Defines how the image will fit into its parent container when using layout="fill" */
    objectFit?: CSSProperties['objectFit'];
    /** Defines how the image is positioned within its parent element when using layout="fill". */
    objectPosition?: CSSProperties['objectPosition'];
    /** Triggered when the image finishes loading */
    onLoad?(): void;
    /** Whether the component should use a blurred image placeholder */
    usePlaceholder?: boolean;
  }

  type DatoImageProps = ForwardRefExoticComponent<ImagePropTypes & RefAttributes<HTMLDivElement>>

export type ResponsiveWebImageProps = {
  url:string
  tw?:string
  ref?:MutableRefObject<undefined>
  responsiveImage: ResponsiveImageType
  customData:{
    master: string,
  }
  testID?:string
  } & Omit<DatoImageProps, 'data'|'className'>

/**
   * Render a true image (non SVG)
   * Depending on data available, we either serve a
   * https://github.com/datocms/react-datocms#progressiveresponsive-image
   * TODO: interpret TW classes as specific props for the Dato component, similar to what we've done in Gatsby
   */
export const ImageImg: FC<ResponsiveWebImageProps> = forwardRef(({
  testID, tw = '', style, url, responsiveImage,
}, ref) => {

  const { aspectRatio, nonAspectRatioTw } = getAspectRatioRelatedStyle(tw)

  const { objectFit, objectPosition, tw: nextTw } = getObjectFitPositionFromTw(nonAspectRatioTw)

  const nextStyle = { ...tailwind(nextTw), ...style, aspectRatio }

  // console.log('-> ResponsiveImageWeb', responsiveImage)
  if (!responsiveImage) {
    console.warn('Falling back on RN image but are we sure we want this?', url)
    const { resizeMode } = getResizeMode(nonAspectRatioTw)
    return <RnImage testID={testID} source={{ uri: url }} style={nextStyle} resizeMode={resizeMode} />
  }

  // @see https://github.com/datocms/react-datocms#layout-mode
  let layout = 'intrinsic'
  if (objectFit || objectPosition) {
    layout = 'fixed'
  }

  // console.log('Will use Dato Image component')
  // console.log('tw', tw)

  return (
    <DatoImage
      id={testID}
      ref={ref}
      data={responsiveImage}
      style={nextStyle}
      layout={layout}
      objectFit={objectFit}
      objectPosition={objectPosition}
    />
  )
})
