import cleanDeep from 'clean-deep'
import { format } from 'date-fns'
import { FC, memo } from 'react'
import {
  CalendlyEventListener,
  InlineWidget,
} from 'react-calendly'
import { Platform } from 'react-native'

import { useInSomeTime } from '~/hooks/dateFns'
import { tailwind } from '~/theme/tailwind'

import { calendlyBackgroundColor, calendlyPrimaryColor, calendlyTextColor } from './Calendly.constants'
import { CalendlyEmbedProps } from './Calendly.types'

export const CalendlyEmbed: FC<CalendlyEmbedProps> = memo(({
  setStep,
  url,
  user,
  setMeetingData,
  eventId,
  tw,
  style,
}) => {
  // open the calendar in the month in two weeks time
  const inTwoWeeksDate = useInSomeTime({ weeks: 2 })
  const monthParam = format(inTwoWeeksDate, 'yyyy-MM')

  const nextStyle = cleanDeep({ ...tailwind(tw), ...style })

  return (
    <CalendlyEventListener
      onDateAndTimeSelected={() => {
        // console.log(' CalendlyEventListener/onDateAndTimeSelected', e.data)
        setStep('confirmation')
      }}
      onEventScheduled={(e) => {
        // console.log(' CalendlyEventListener/onEventScheduled', e.data)
        setStep('scheduled')
        setMeetingData({
          calendlyMeetingId: e.data.payload.event.uri.split('/').slice(-1)[0] as string,
        })
      }}
      onEventTypeViewed={() => {
        // console.log(' CalendlyEventListener/onEventTypeViewed', e.data)
        setStep('date_time_selection')
      }}
      >
      <InlineWidget
        url={`${url}?month=${monthParam}`}
        styles={nextStyle}
        pageSettings={{
          backgroundColor: calendlyBackgroundColor,
          primaryColor: calendlyPrimaryColor,
          textColor: calendlyTextColor,
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
          hideGdprBanner: true,
        }}
        prefill={cleanDeep({
          email: user.email ? user.email : undefined,
          name: user.displayName ? user.displayName : undefined,
          customAnswers: {
            a1: user.phoneNumber ? user.phoneNumber : undefined,
          },
        })}
        utm={{
          // utmCampaign: 'Spring Sale 2019',
          utmContent: eventId,
          utmMedium: Platform.OS,
          utmSource: 'App',
          utmTerm: user.uid,
        }}
      />
    </CalendlyEventListener>
  )
})
