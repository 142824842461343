import { FC } from 'react'
import {
  FieldName, FieldValues, useController, useFormContext,
} from 'react-hook-form'
import { Switch } from 'react-native-elements'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
// import { SwitchProps } from '~/elements/switch/SwitchProps'
import { getHexColorFromTwColor } from '~/theme/helpers'
import { tailwind } from '~/theme/tailwind'

export type CheckboxInputProps<
TFieldValues extends FieldValues = FieldValues
> = {
  name:FieldName<TFieldValues>
  defaultValue?:boolean
  label?:string
}

export const CheckboxInput:FC<CheckboxInputProps> = (props) => {
  const {
    name, label, defaultValue,
  } = props

  const { control, setValue } = useFormContext()
  const {
    field: {
      name: fieldName, value,
    },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || false,
  })

  return (
    <View tw="flex-row">
      <Switch
        testID={name}
        trackColor={{ false: getHexColorFromTwColor('brand-lightest'), true: getHexColorFromTwColor('brand-base') }}
        thumbColor={getHexColorFromTwColor('surface-1')}
        // ios_backgroundColor="#3e3e3e"
        onValueChange={(val) => {
          setValue(fieldName, val)
        }}
        value={value}
        style={tailwind('grow-0')}
      />

      <View tw=" flex-grow h-16">
        {/* <Text tw="text-gray-500 text-sm bg-purple-500">{label}</Text> */}
        <Text tw="text-gray-500 text-sm flex-wrap" markdown>{label}</Text>
      </View>

    </View>
  )
}
