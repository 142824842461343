import { Link as RnLink } from '@react-navigation/native'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

import { Image } from '~/elements/images/Image'
// import { useColorScheme } from 'react-native'
import { useDatoSvg } from '~/hooks/dato.assets'
import { prefixMatch } from '~/theme/tailwind'

export const ICON_NO_PAD_BRAND = '24241675'
export const ICON_NO_PAD_WHITE = '24241676'
export const ICON_NO_PAD_BLACK = '24241674'

export const LOGO_NO_PAD_BRAND = '10719066'
export const LOGO_NO_PAD_WHITE = '24241697'
export const LOGO_NO_PAD_BLACK = '24241696'

export type LogoColor = 'brand' | 'white' | 'black'

/**
 * A logo that adjusts color scheme
 */
export const BrandLogo = ({ tw }) => {
  const lg = prefixMatch('lg')
  // console.log('lg', lg)

  const colorScheme = 'light' // useColorScheme()

  const [assetId, setAssetId] = useState(ICON_NO_PAD_BRAND)

  useEffect(() => {
    // console.log('colorScheme', colorScheme)
    // console.log('lg', lg)
    if (lg && colorScheme === 'light') {
      setAssetId(LOGO_NO_PAD_BRAND)
    } else if (lg && colorScheme === 'dark') {
      setAssetId(LOGO_NO_PAD_WHITE)
    } else if (!lg && colorScheme === 'light') {
      setAssetId(ICON_NO_PAD_BRAND)
    } else if (!lg && colorScheme === 'dark') {
      setAssetId(ICON_NO_PAD_WHITE)
    }
  }, [colorScheme, lg])

  const { data } = useDatoSvg(assetId)
  // console.log('data', data)

  return (
    <RnLink to="/">
      {data && (<Image {...data.upload} tw={classNames('h-10 lg:h-14', tw)} />)}
    </RnLink>
  )
}
