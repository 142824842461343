// eslint-disable-next-line import/no-extraneous-dependencies
import { from } from 'nearest-color'
import resolveConfig from 'tailwindcss/resolveConfig'

import { getBgColor, getTextColor } from '~/elements/colors/colors.helpers'
import { MainColorShade } from '~/elements/colors/ColorShades'
import { AspectRatio } from '~/elements/images/DoubleSvgImage/DoubleSvgImage.enums'

import tailwindConfig from '../tailwind.config.js'

const {
  theme: {
    colors, width, height, aspectRatio, borderRadius, lineHeight, fontSize,
  },
} = resolveConfig(tailwindConfig)

// console.log('tailwind colors', colors)

export const getHexColorFromTwColor = (color:string) => {
  // console.log('color', color)
  const [baseColor, shade] = color.split('-')
  if (shade) {
    return colors[baseColor][shade]

  }
  return colors[baseColor]

}

const twColors = Object.keys(colors).reduce((acc, base) => {

  if (typeof colors[base] === 'string') {
    return { ...acc, [base]: colors[base] }
  }

  const obj = Object.keys(colors[base]).reduce((shades, shade) => ({ ...shades, [`${base}-${shade}`]: colors[base][shade] }), {})
  return { ...acc, ...obj }

}, {})

const twColorsNoTransparent = Object.keys(twColors).reduce((acc, color) => {
  if (color.includes('transparent')) {
    return acc
  }
  return { ...acc, [color]: twColors[color] }
}, {})

// console.log('twColors', twColorsNoTransparent)

export const getNearestTailwindColor = (hex:string) => {
  // console.log('getNearestTailwindColor for ', hex)

  const nearestColorUtil = from(twColorsNoTransparent)
  // console.log('nearestColorUtil', nearestColorUtil)

  const res = nearestColorUtil(hex)
  // console.log('res', res)

  return res.name
}

// 1rem = 16px
export const REM_VALUE = 16

export const getPxFromRem = (remVal) => Number(remVal.replace('rem', '')) * REM_VALUE

// w-32 => 128 (px)
export const getWidth = (w:string) => {
  // console.log('width', width)
  const remVal = width[w.split('-')[1]]
  // console.log('remVal', remVal)
  // console.log('theme', resolveConfig(tailwindConfig))
  return getPxFromRem(remVal)
}

// 250px => w-62
export const getNearestHeight = (heightPx:number) => {
  // console.log('height', height)
  const distances = Object.keys(height).map((key) => ({ val: key, distance: Math.abs(heightPx - Number(height[key].replace('rem', '')) * REM_VALUE) }))
  // console.log('distances', distances)
  const sortedDistances = distances.filter((o) => !Number.isNaN(o.distance)).sort((a, b) => a.distance - b.distance)
  // console.log('sorted heights', sortedDistances)
  return `h-${sortedDistances[0].val}`

}

// w-24, aR=1.5 => h-36
export const getNearestRatioHeight = (twWidth:string, ar : number) => {
  // console.log('-> getNearestRatioHeight', twWidth, aspectRatio)
  if (ar === 1) {
    return `h-${twWidth.split('-')[1]}`
  }
  const widthPx = getWidth(twWidth)
  // console.log('widthPx', widthPx)

  const heightPx = widthPx / ar
  // console.log('heightPx', heightPx)
  return getNearestHeight(heightPx)
}

// 1 => '1:1'
export const getNearestAspectRatio = (ar:number):AspectRatio => {
  // console.log('aspectRatio', aspectRatio)
  const allAspectRatio = Object.keys(aspectRatio).reduce((acc, aspectW) => {
    const valForAspectW = Object.keys(aspectRatio).map((aspectH) => ({ aspectW, aspectH, ratio: aspectW / aspectH }))
    return [...acc, ...valForAspectW]
  }, [])
  // console.log('allAspectRatio', allAspectRatio)

  const distances = allAspectRatio.map(({ aspectW, aspectH, ratio }) => ({ aspectW, aspectH, distance: Math.abs(ratio - ar) }))
  // console.log('distances', distances)
  const sortedDistances = distances.filter((o) => !Number.isNaN(o.distance)).sort((a, b) => a.distance - b.distance)
  // console.log('sorted heights', sortedDistances)
  const nearestVal = sortedDistances[0]
  // console.log('nearestVal', nearestVal)

  return `${nearestVal.aspectW}:${nearestVal.aspectH}`
}

const getPxValue = (val:string) => {
  if (!val) {
    return 0
  }
  if (val.endsWith('rem')) {
    return Number(val.replace('rem', '')) * REM_VALUE
  }
  if (val.endsWith('px')) {
    return Number(val.replace('px', ''))
  }
  throw new Error(`unhandled case for val ${val}`)
}

export const getRoundingValue = (val:string) => {
  const remVal = borderRadius[val.split('-')[1]]
  return getPxValue(remVal)
}

// TODO replace DEFAULT with ''
// 23 => rounded-sm
export const getNearestRoundingTw = (rounding:number) => {
  if (!rounding) {
    return ''
  }
  // console.log('aspectRatio', aspectRatio)
  const distances = Object.keys(borderRadius).map((key) => ({ val: key, distance: Math.abs(rounding - getPxValue(borderRadius[key])) }))
  // console.log('distances', distances)
  const sortedDistances = distances.filter((o) => !Number.isNaN(o.distance)).sort((a, b) => a.distance - b.distance)
  // console.log('sorted heights', sortedDistances)
  if (sortedDistances[0].val === 'DEFAULT') {
    return 'rounded'
  }
  return `rounded-${sortedDistances[0].val}`
}

export const getPaddingAdaptedValue = (roundingTw:string, paddingTw:string):string => {
  // console.log('->getPaddingAdaptedValue', roundingTw, paddingTw)
  if (!paddingTw || paddingTw.length === 0) {
    return roundingTw
  }
  const padding = getWidth(paddingTw)
  // console.log('padding', padding)
  const rounding = getRoundingValue(roundingTw)
  // console.log('rounding', rounding)

  const delta = rounding - padding

  return getNearestRoundingTw(delta)
}

export const getLightestColorShade = (color:MainColorShade):MainColorShade => {

  const [baseColor] = color.split('-')

  switch (baseColor) {
  case 'orange':
  case 'yellow':
  case 'red':
  case 'purple':
  case 'blue':
  case 'teal':
  case 'green':
  case 'gray':
    return `${baseColor}-100`
  case 'brand':
  case 'basic':
  case 'success':
  case 'info':
  case 'warning':
  case 'danger':
    return `${baseColor}-lightest`
  default:
    throw new Error(`unhandled color ${baseColor}`)
  }

}

/**
 * Returns the default color for a shade (ie -500 or -base)
 */
export const getMainColorShade = (color:MainColorShade):MainColorShade => {

  const [baseColor] = color.split('-')

  switch (baseColor) {
  case 'orange':
  case 'yellow':
  case 'red':
  case 'purple':
  case 'blue':
  case 'teal':
  case 'green':
  case 'gray':
    return `${baseColor}-500`
  case 'brand':
  case 'basic':
  case 'success':
  case 'info':
  case 'warning':
  case 'danger':
    return `${baseColor}-base`
  default:
    throw new Error(`unhandled color ${baseColor}`)
  }

}

/**
 * Provide the light background color for a given shade
 * Used for instance in buttons, or contained icons
 */
export const getLightBgColorForColorShade = (color:MainColorShade) => getBgColor(getLightestColorShade(color))

/**
 * Returns the standard text color for a given shade
 * Used for instance in buttons, or contained icons
 */
export const getTextColorForColorShade = (color:MainColorShade) => {

  const [baseColor] = color.split('-')

  switch (baseColor) {
  case 'orange':
  case 'yellow':
  case 'red':
  case 'purple':
  case 'blue':
  case 'green':
  case 'gray':
    return getTextColor(`${baseColor}-500`)
  case 'teal':
    return getTextColor(`${baseColor}-700`)
  case 'brand':
  case 'basic':
  case 'success':
  case 'info':
  case 'warning':
  case 'danger':
    return getTextColor(`${baseColor}-base`)
  default:
    throw new Error(`unhandled color ${baseColor}`)
  }

}

// leading-8 => 2rem , leading-snug => 1.375
export const getLineHeightValue = (twClass:string) => lineHeight[twClass.replace('leading-', '')]

// text-base => 16
export const getFontSizePt = (twClass:string) => getPxValue(fontSize[twClass.replace('text-', '')])
