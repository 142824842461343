import classNames from 'classnames'
import { FC, ReactNode, useRef } from 'react'

import { SafeAreaView } from '~/elements/containers/SafeAreaView'
import { ScrollView } from '~/elements/containers/ScrollView'
// import { ViewProps } from '~/elements/containers/View'

type MainContainerProps = {
  tw?: string
  contentContainerTw?: string
  // style?: ViewProps
  children: ReactNode
  scrollEnabled?: boolean
  scrollToTop?: boolean
  edges?:Array<'top'|'bottom'|'left'|'right'>
}

/**
 * Narrow constrained with padded content
 * https://tailwindui.com/components/application-ui/layout/containers#component-19e43f3ad1f1f984ec0c02bdb305353f
 */
export const MainContainer: FC<MainContainerProps> = ({
  tw,
  contentContainerTw,
  children,
  scrollEnabled = false,
  scrollToTop = false,
  edges = [],
}) => {
  // console.log('tw', tw)
  const scrollRef = useRef(null)
  if (scrollEnabled) {
    const contentContainerStyle = classNames('flex-col items-start pb-24', contentContainerTw)
    if (scrollToTop) {
      scrollRef.current?.scrollTo({ y: 0, animated: true })
    }

    return (
      <SafeAreaView edges={edges} tw="flex-1">
        <ScrollView
          testID="main-container"
          tw={classNames('h-full', tw)}
          showsVerticalScrollIndicator={false}
          contentContainerTw={contentContainerStyle}
          ref={scrollRef}
          >
          {children}
        </ScrollView>
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView
      testID="main-container"
      tw={classNames('h-full flex-1', tw)}
      edges={edges}
      >
      {children}
    </SafeAreaView>
  )
}
