import { useNavigation } from '@react-navigation/core'
import { useLinkTo } from '@react-navigation/native'
import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { prefixMatch } from '~/theme/tailwind'

import { Button } from '../Button'
import { baseTwClasses, iconFamilyVariant, iconSize } from './headerButtons.helpers'
import { HeaderIconButton, HeaderIconButtonProps } from './HeaderIconButton'

// perhaps should reimplement a more native button (cf React Navigation ) or at least change design based on platform?
// See https://reactnavigation.org/docs/elements#headerbackbutton

type GoBackHeaderButtonProps = {
  label?:string|false,
  tw?:string
} & Omit<Omit<HeaderIconButtonProps, 'icon'>, 'size'>

export const GoBackHeaderButton:FC<GoBackHeaderButtonProps> = ({
  tw, label, color, ...props
}) => {
  const navigation = useNavigation()

  const { t } = useTranslation(['common'])

  const linkTo = useLinkTo()

  const canGoBack = navigation.canGoBack()

  if (!canGoBack && !props.to && !props.onPress) {
    // console.log('GoBackHeaderButton: no way to go back')
    return null
  }

  const onPressGoBackButton = () => {
    // console.log('GoBackHeaderButton: onPressGoBackButton')
    if (navigation && canGoBack) {
      // console.log('Will go back in navigation history')
      // Fix as in some cases navigation has recorded multiple similar screens i/o one
      navigation.goBack()
    } else if (props.onPress) {
      // console.log('Will honor onPress')
      props.onPress()
    } else if (props.to) {
      // console.log('Will go to', props.to)
      linkTo(props.to)
    }
  }

  if (prefixMatch('lg') && label !== false) {
    return (
      <Button
        icon={{
          name: 'arrow-left-long',
          variant: 'regular',
        }}
        onPress={onPressGoBackButton}
        variant="ghost"
        iconRight={false}
        color={color}
        title={label || t('common:cta.back')}
        tw="px-0 ml-2 text-gray-900"
      />
    )
  }

  return (
    <HeaderIconButton
      {...props}
      tw={classNames(tw, baseTwClasses)}
      icon={{ variant: iconFamilyVariant, sizeNum: iconSize, name: 'chevron-left' }}
      onPress={onPressGoBackButton}
    />
  )
}
