import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useTranslation } from 'react-i18next'

import { tailwind } from '~/theme/tailwind'

import { BottomTabNavigatorIcon } from '../navigators.shared/BottomTabNavigatorIcon'
import { discoverItem, nonVisibleScreens, useMenuItems } from './menuItems'
import { MenuParamList } from './MenuParamList'

const BottomTabStack = createBottomTabNavigator<MenuParamList>()

export const BottomTabNavigator = () => {
  // console.log('-> BottomTabNavigator')
  const { t } = useTranslation(['navigation'])
  const { isLoading, data: menuItems } = useMenuItems()

  // insert Discover item in third position
  menuItems?.splice(2, 0, discoverItem)

  if (isLoading) {
    return null
  }

  return (
    <BottomTabStack.Navigator
      screenOptions={{
        headerShown: false,
        lazy: true,
        tabBarShowLabel: false,
        tabBarStyle: {
          ...tailwind('border-t-0 bg-surface-1'),
          height: 68,
        },
      }}
      >
      {menuItems.filter((item) => !nonVisibleScreens.includes(item.name)).map((item) => (
        <BottomTabStack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            tabBarLabel: t(`menu.${item.key}`),
            // eslint-disable-next-line react/no-unstable-nested-components
            tabBarIcon: (props) => <BottomTabNavigatorIcon {...props} {...item.icon} />,

          }}
        />
      ))}
    </BottomTabStack.Navigator>
  )
}
