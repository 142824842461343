import classNames from 'classnames'

import { SwiperByNameQuery } from '~/__generated__/graphql.queries'
import { getBgColor, getTextColor } from '~/elements/colors/colors.helpers'
import { SafeAreaView } from '~/elements/containers/SafeAreaView'
import { View } from '~/elements/containers/View'
import { Image } from '~/elements/images/Image'
import { Text } from '~/elements/text/Text'

export const CarouselSlide = (item: SwiperByNameQuery) => {

  const titleSize = 'text-base lgv:text-lg xlv:text-xl'
  // console.log('titleSize',titleSize)
  const descSize = 'text-xs smv:text-sm lgv:text-base'
  // console.log('descSize',descSize)
  // console.log('item', item.illustration)

  const titleTwClasses = classNames(
    'text-center font-semibold font-serif px-8 max-w-md',
    titleSize,
    getTextColor(item.titleColor || 'gray-900'),
  )
  const descriptionTwClasses = classNames('text-center px-8 mt-4 md:mt-8 max-w-md', descSize)
  const containerTw = classNames(
    'items-center flex-col justify-evenly w-full h-full pb-16 smv:pb-24 lgv:pb-28',
    getBgColor(item.illustration.backgroundColor),
  )

  // eslint-disable-next-line no-nested-ternary
  const imgBottomMargin = 'mb-1 smv:mb-4 lgv:mb-8'

  return (
    <SafeAreaView tw={containerTw}>
      {item.illustration && (
        <Image {...item.illustration.foreground} tw={classNames('h-2/5', imgBottomMargin)} />
        )}
      <View tw="">
        {!!item.title && <Text tw={titleTwClasses}>{item.title}</Text>}
        {!!item.description && <Text tw={descriptionTwClasses}>{item.description}</Text>}
      </View>
    </SafeAreaView>
  )
}
