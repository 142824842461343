import classNames from 'classnames'
import {
  FC, useRef, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import RnSwiper from 'react-native-web-swiper'

import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Swiper } from '~/elements/swiper/Swiper'
import { useSwiperData } from '~/hooks/dato.swipers'
import { useBtnSizeFromVertBp } from '~/hooks/theme.breakpoint.vertical.helpers'
import { tailwind } from '~/theme/tailwind'

import { CarouselSlide } from './CarouselSlide'

type CarouselProps = {
  tw?: string
  /**
   * One of the swipers keys. See https://cms.mindday.com/editor/item_types/1151671/items
   * TODO: dynamically build that type
   */
  swiperKey: 'evaluation-player' | 'app-welcome-v2'
  buttonTitle?: string
  buttonLastTitle?: string
  onLast?: () => void

}

/**
 * Wraps Swiper component with data coming from Dato CMS
 * TODO: custom slide background color based on slide definition
 */
export const Carousel: FC<CarouselProps> = ({
  tw,
  swiperKey,
  buttonTitle,
  buttonLastTitle,
  onLast,
}) => {
  // console.log('-> Carousel', swiperKey)
  // console.log('tw', tw)
  const { t } = useTranslation(['onboarding', 'common'])

  const currentRef = useRef<RnSwiper>(null)
  const { data } = useSwiperData(swiperKey)
  const [isLast, setIsLast] = useState(false)

  const btnSize = useBtnSizeFromVertBp()
  // console.log('btnSize', btnSize)

  if (!data || !data.appSwiper) {
    return null
  }

  // console.log(`data`, data)

  const { slides } = data.appSwiper

  // console.log('slides', slides)

  const onPressNavigationButton = () => {
    if (onLast && isLast) {
      onLast()
    } else {
      currentRef.current?.goToNext()
    }
  }

  const onIndexChanged = (activeIndex: number) => {
    const isLastSlide = (slides.length - 1) === activeIndex
    if (isLastSlide) {
      setIsLast(true)
    } else {
      setIsLast(false)
    }
  }

  const onIgnore = () => {
    if (onLast) {
      onLast()
    }
  }

  const bottomView = (onLast && buttonTitle && buttonLastTitle)
    ? (
      <View tw="w-full flex-row justify-between">
        <Button
          color="control"
          variant="ghost"
          size={btnSize}
          tw="text-basic-darker uppercase"
          onPress={onIgnore}
          title={t('common:cta.ignore')}
        />
        <Button
          onPress={onPressNavigationButton}
          size={btnSize}
          title={isLast ? buttonLastTitle : buttonTitle}
        />
      </View>
    ) : undefined

  const innerContainerPadding = 'pb-0'
  const bottomCellHeight = 'h-16 smv:h-24 lgv:h-28'

  return (
    <Swiper
      ref={currentRef}
      containerTw={classNames('h-screen w-full', tw)} // No padding here
      innerContainerTw={innerContainerPadding} // padding for dots and ctas
      // swipeAreaStyle=""
      slideWrapperTw="flex items-center justify-center"
      dotActiveTw="bg-black w-6"
      onIndexChanged={onIndexChanged}
      controlsProps={{
          dotsTouchable: true,
          dotsPos: 'bottom',
          prevPos: false,
          nextPos: false,
          cellsContent: {
            bottom: bottomView,
          },
          cellsStyle: {
            bottom: tailwind(classNames('w-full flex-col content-center justify-between bottom-1', bottomCellHeight)),
          },
      }}
      >
      {slides.map((item) => <CarouselSlide key={`slide-${item.id}`} testID={`slide-${item.id}`} {...item} />)}
    </Swiper>
  )
}
