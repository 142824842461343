import classNames from 'classnames'
import { AnimatePresence, MotiTransitionProp } from 'moti'
import { ReactChild, useState } from 'react'

import { getHexColorFromTwColor } from '~/theme/helpers'
import { tailwind } from '~/theme/tailwind'
import { getRoundedRelatedClasses, getSizeRelatedClasses } from '~/utils/tailwind/tw.containers.helpers'

import { AnimatedView } from '../containers/AnimatedView'
import { View } from '../containers/View'
import { AnimatedGradient } from './AnimatedGradient'

type Props = {
    /**
     * Optional height of the container of the skeleton. If set, it will give a fixed height to the container.
     *
     * If not set, the container will stretch to the children.
     */
    boxHeight?: number | string

    children?: ReactChild
    /**
     * `boolean` specifying whether the skeleton should be visible. By default, it shows if there are no children. This way, you can conditionally display children, and automatically hide the skeleton when they exist.
     *
     * ```tsx
     * // skeleton will hide when data exists
     * <Skeleton>
     *   {data ? <Data /> : null}
     * </Skeleton>
     * ```
     *
     * // skeleton will always show
     * <Skeleton show>
     *   {data ? <Data /> : null}
     * </Skeleton>
     *
     * // skeleton will always hide
     * <Skeleton show={false}>
     *   {data ? <Data /> : null}
     * </Skeleton>
     */
    show?: boolean

    /**
     * Primary way to style our skeleton.
     * we expect at least info about height, width, and border radius
     * h-24 w-24 rounded-2xl
     */
    tw:string

    /**
     * Optional height of the skeleton. Defauls to a `minHeight` of `32`
     */
  //    height?: number | string

    /**
     * Width of the skeleton. Defaults to `32` as the `minWidth`. Sets the container's `minWidth` to this value if defined, falling back to 32.
     */
  //   width?: string | number
    /**
     * Border radius. Can be `square`, `round`, or a number. `round` makes it a circle. Defaults to `8`.
     */
  //   radius?: number | 'square' | 'round'
    /**
     * Background of the box that contains the skeleton. Should match the main `colors` prop color.
     *
     * Default: `'rgb(51, 51, 51, 50)'`
     */
    backgroundColor?: string

    /**
     * Gradient colors. Defaults to grayish black.
     */
    colors?: string[]

    /**
     * Default: `6`. Similar to `600%` for CSS `background-size`. Determines how much the gradient stretches.
     */
    backgroundSize?: number

    /**
     * `light` or `dark`. Default: `dark`.
     */
    colorMode?: keyof typeof baseColors
    disableExitAnimation?: boolean
    transition?: MotiTransitionProp
  }

const DEFAULT_SIZE = 32

const baseColors = {
  dark: { primary: getHexColorFromTwColor('gray-500'), secondary: getHexColorFromTwColor('gray-300') },
  light: {
    primary: getHexColorFromTwColor('surface-1'),
    secondary: getHexColorFromTwColor('surface-2'),
  },
} as const

const makeColors = (mode: keyof typeof baseColors) => [
  baseColors[mode].primary,
  baseColors[mode].secondary,
  baseColors[mode].secondary,
  baseColors[mode].primary,
  baseColors[mode].secondary,
  baseColors[mode].primary,
]

let defaultDarkColors = makeColors('dark')

let defaultLightColors = makeColors('light')

// eslint-disable-next-line no-plusplus
for (let i = 0; i++; i < 3) {
  defaultDarkColors = [...defaultDarkColors, ...defaultDarkColors]
  defaultLightColors = [...defaultLightColors, ...defaultLightColors]
}

export const Skeleton = (props: Props) => {
  const {
    children,
    show = !children,
    boxHeight,
    colorMode = 'dark',
    colors = colorMode === 'dark' ? defaultDarkColors : defaultLightColors,
    backgroundColor = colors[0]
      ?? colors[1]
      ?? baseColors[colorMode]?.secondary,
    backgroundSize = 6,
    disableExitAnimation,
    transition,
    tw = '',
  } = props

  const [measuredWidth, setMeasuredWidth] = useState(0)

  const { sizeTw, nonSizeTw } = getSizeRelatedClasses(tw)
  const sizeProps = tailwind(classNames('h-6 w-full', sizeTw))

  const { roundedTw, nonRoundedTw: containerTw } = getRoundedRelatedClasses(nonSizeTw)

  const getOuterHeight = () => {
    if (boxHeight != null) {
      return boxHeight
    }
    if (show && !children) {
      return sizeProps.height
    }
    return undefined
  }

  const outerHeight = getOuterHeight()

  return (
    <View
      tw={containerTw}
      style={{
        height: outerHeight,
        minHeight: sizeProps.height,
        minWidth: sizeProps.width ?? (children ? undefined : DEFAULT_SIZE),
      }}
      >
      {children}
      <AnimatePresence>
        {show && (
          <AnimatedView
            tw={classNames('absolute top-0 left-0 overflow-hidden', roundedTw || 'rounded')}
            style={{
              width: sizeProps.width ?? (children ? '100%' : DEFAULT_SIZE),
              height: sizeProps.height ?? '100%',
            }}
            animate={{ backgroundColor, opacity: 1 }}
            transition={{ type: 'timing' }}
            exit={
              !disableExitAnimation && {
                opacity: 0,
            }
            }
            onLayout={({ nativeEvent }) => {
              if (measuredWidth === nativeEvent.layout.width) {
                return
              }

              setMeasuredWidth(nativeEvent.layout.width)
            }}
            pointerEvents="none"
            >
            <AnimatedGradient
              // force a key change to make the loop animation re-mount
              key={`${JSON.stringify(colors)}-${measuredWidth}-${JSON.stringify(
                transition || null,
              )}`}
              colors={colors}
              backgroundSize={backgroundSize}
              measuredWidth={measuredWidth}
              transition={transition}
            />
          </AnimatedView>
        )}
      </AnimatePresence>
    </View>
  )
}
