import { FC, forwardRef, useRef } from 'react'
import { Pressable as RnPressable, PressableProps as RnPressableProps } from 'react-native'

import { useStateAwareTailwind } from '~/hooks/tailwind'

import { adaptOutlineClasses, adaptRingClasses, getAspectRatio } from './tw.helpers'

type BasePressableProps = {
  tw?: string; // Tailwind classes
} & Omit<RnPressableProps, 'style'>;

// Now we just need to convert tailwind classes into styles
export const BasePressable: FC<BasePressableProps> = forwardRef(({ tw, style, ...props }, ref) => {
  // console.log('BasePressable', tw)
  // console.log('BasePressable/onPress', props.onPress)

  /**
    * map tailwind classes to RN styles, accounting for states
    */
  const newRef = useRef(null)
  const pressableRef = ref || newRef

  const tailwind = useStateAwareTailwind(pressableRef)

  let finalTw = tw

  // converts ring related classes into borders
  finalTw = adaptRingClasses(finalTw)

  const foo = adaptOutlineClasses(finalTw)
  finalTw = foo.tw

  // remove aspect ratio classes
  const arRes = getAspectRatio(finalTw)

  finalTw = arRes.tw

  // console.log('BasePressable/finalTw', finalTw)

  const nextStyle = {
    ...tailwind(finalTw),
    ...foo.style,
    ...style,
  }

  if (arRes.aspectRatio) {
    nextStyle.aspectRatio = arRes.aspectRatio
  }

  // console.log('Pressable tw', tw)

  // console.log('Pressable style', style)
  // console.log('remaining props', props)
  return <RnPressable ref={pressableRef} {...props} style={nextStyle} />
})
