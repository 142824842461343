import { StackScreenProps } from '@react-navigation/stack'
import get from 'lodash/get'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { useTypeformBySlug } from '~/hooks/dato.typeforms'
import { useRedirectAfterDelay } from '~/hooks/time'
import { EvaluationsStackParamList } from '~/navigators/EvaluationsStackParamList'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

type NewFormThankYouScreenProps = StackScreenProps<EvaluationsStackParamList, 'NewTypeformComplete'>

export const NewFormThankYouScreen:FC<NewFormThankYouScreenProps> = ({ route }) => {

  const { slug } = route.params

  const { isLoading, data } = useTypeformBySlug(slug)
  const { t } = useTranslation(['common', 'my-evaluations'])

  const redirectTo = get(data, 'form.to') || '/'

  useRedirectAfterDelay({ to: redirectTo, delay: 2500 })

  if (isLoading) {
    return <LoadingScreen />
  }

  const outro = get(data, 'form.outro')

  // console.log('outro', outro)

  const desc = outro.length > 0 ? outro : t('my-evaluations:complete.description')

  return (
    <MainContainer tw="flex-col items-center md:items-start w-full">
      <BasicSectionContainer tw="">
        <View tw="p-8 w-full flex-col bg-surface-1 mt-8 rounded-2xl content-center">
          <Text tw="font-serif text-2xl font-semibold text-center">{t('my-evaluations:complete.title')}</Text>
          <Text tw="mt-4 text-center mb-8">{desc}</Text>
          <Button title={t('common:cta.continue')} to={redirectTo} />
        </View>
      </BasicSectionContainer>
    </MainContainer>
  )

}
