import { StackHeaderTitleProps } from '@react-navigation/stack'
import classNames from 'classnames'

import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'
import { prefixMatch } from '~/theme/tailwind'

export const CustomHeaderTitle = ({ tw, children }: StackHeaderTitleProps) => (
  <View tw={classNames('mt-2', tw)}>
    <Text
      numberOfLines={prefixMatch('md') ? undefined : 1}
      tw="font-serif leading-normal text-lg sm:text-xl md:text-2xl lg:text-3xl"
      >
      {children}
    </Text>
  </View>
)
// }
