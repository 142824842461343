import { useActive, useFocus, useHover } from 'react-native-web-hooks'

import { tailwind } from '~/theme/tailwind'
import { getTwForInteractionState, StateModifier } from '~/utils/tailwind/tw.interaction.helpers'

/**
 * Custom utility that provides compatiblity woth state related prefixes
 * See - https://tailwindcss.com/docs/hover-focus-and-other-states#pseudo-classes
 */
export const useStateAwareTailwind = (ref, customModifiers = {}) => {
  const hover = useHover(ref)
  const focus = useFocus(ref)
  const active = useActive(ref)

  const state:Record<StateModifier, boolean> = {
    hover, focus, active, ...customModifiers,
  }

  return (tw:string) => tailwind(getTwForInteractionState(tw, state))

}
