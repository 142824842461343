import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { SectionTwoColContainer } from '~/components/layouts/column-sections/SectionTwoColContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { MinderDesktopSideCard } from '~/components/panels/cards/MinderDesktopSideCard'
import { useCurrentUserMinderSlug } from '~/hooks/minder'

import { BookMeetingCardOrCta } from './BookMeetingCardOrCta'
import { MyTrackProps } from './MyTrackProps'
import { SessionPlannedSection } from './SessionPlannedSection'
import { SessionsDoneSection } from './SessionsDoneSection'

export const MyTrackDesktopScreen: FC<MyTrackProps> = (props) => {
  // console.log('-> MyTrackDesktopScreen')
  const { isLoading, data } = useCurrentUserMinderSlug()
  const { t } = useTranslation(['track'])
  const RightColumn = useCallback(() => (
    <>
      {!isLoading && !!data && <MinderDesktopSideCard slug={data} />}
      <BookMeetingCardOrCta {...props} tw="mt-4" />
    </>
  ), [isLoading, data, props])

  return (
    <MainContainer scrollEnabled contentContainerTw="flex-col items-start w-full">
      <ScreenHeadingSection tw="mb-4" title={t('track:main.title')} description={t('track:main.description')} />
      <SectionTwoColContainer
        tw="w-full max-w-4xl pl-8"
        Secondary={RightColumn}
        mainTw="pr-2"
        secondaryTw="pl-2"
        >
        <SessionPlannedSection />
        <SessionsDoneSection />
      </SectionTwoColContainer>
    </MainContainer>
  )
}
