import omit from 'lodash/omit'
import {
  CSSProperties, FC, forwardRef, MutableRefObject,
} from 'react'

import { tailwind } from '~/theme/tailwind'

// import { getObjectFitPositionFromTw } from '~/utils/tailwind/tw.gatsbyImages.helpers'
import { getAspectRatio, getDimensions } from './ImageSvg.helpers'

export type DomImgProps = {
  alt:string
  style: CSSProperties
  width:number
  height:number
  src:string
  srcset?:Array<string>
}

export type ImageSvgProps = {
  url: string
  tw?:string
  ref?:MutableRefObject<undefined>
  testID?:string
  customData:{
    fallback: string,
  }
} & Omit<DomImgProps, 'src'>

/**
 * Renders an SVG image on web
 * Note: as we use DOM emlement here we can directly use tw classes
 */
export const ImageSvg:FC<ImageSvgProps> = forwardRef(({
  testID, url, alt, tw = '', style = {}, height, width,
}, ref) => {
  // console.log('-> ImageSvg', url, tw, height, width)

  const nextStyle = omit(style, ['width', 'height', 'aspectRatio'])

  const { aspectRatio, remaining: nonAspectRatioTw } = getAspectRatio(tw, style, width, height)

  const { height: resHeight, width: resWidth, remaining } = getDimensions(aspectRatio, nonAspectRatioTw, style, width, height, tailwind)

  const locHeight = resHeight || undefined
  const locWidth = resWidth || undefined

  if (aspectRatio && (!locHeight || !locWidth)) {
    nextStyle.aspectRatio = aspectRatio
  }

  // console.log('Will render web SVG via <img> tag')
  // console.log('url', url)
  // console.log('height', locHeight)
  // console.log('width', locWidth)
  // console.log('tw', remaining)
  // console.log('style', nextStyle)

  return (
    <img
      id={testID}
      ref={ref}
      src={url}
      className={remaining}
      style={nextStyle}
      alt={alt}
      height={locHeight}
      width={locWidth}
    />
  )
})
