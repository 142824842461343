import type { EmojiData } from 'emoji-mart'
import {
  BaseSyntheticEvent, ChangeEventHandler,
  // ClipboardEvent,
  CSSProperties,
  // FocusEventHandler,
  Key, memo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'react-native'
import type { CommandResponse, UserResponse } from 'stream-chat'

import type {
  CustomTrigger,
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '~/components/chat/stream.types'
import { tailwind } from '~/theme/tailwind'

import { useChannelStateContext } from '../context/ChannelStateContext'
import { useMessageInputContext } from '../context/MessageInputContext'
import { useTranslationContext } from '../context/TranslationContext'
import { TriggerSettings } from '../MessageInput/DefaultTriggerProvider'

type ObjectUnion<T> = T[keyof T];

export type SuggestionCommand<
  Co extends DefaultCommandType = DefaultCommandType
> = CommandResponse<Co>;

export type SuggestionUser<Us extends DefaultUserType<Us> = DefaultUserType> = UserResponse<Us>;

export type SuggestionItemProps<
  Co extends DefaultCommandType = DefaultCommandType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  className: string;
  component: JSX.Element;
  item: EmojiData | SuggestionUser<Us> | SuggestionCommand<Co>;
  key: Key;
  onClickHandler: (event: BaseSyntheticEvent) => void;
  onSelectHandler: (item: EmojiData | SuggestionUser<Us> | SuggestionCommand<Co>) => void;
  selected: boolean;
  style: CSSProperties;
  value: string;
};

export type SuggestionListProps<
  Co extends DefaultCommandType = DefaultCommandType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
> = ObjectUnion<
  {
    [key in keyof TriggerSettings<Co, Us, V>]: {
      component: TriggerSettings<Co, Us, V>[key]['component'];
      dropdownScroll: (element: HTMLDivElement) => void;
      getSelectedItem:
        | ((item: Parameters<TriggerSettings<Co, Us, V>[key]['output']>[0]) => void)
        | null;
      getTextToReplace: (
        item: Parameters<TriggerSettings<Co, Us, V>[key]['output']>[0],
      ) => {
        caretPosition: 'start' | 'end' | 'next' | number;
        text: string;
        key?: string;
      };
      onSelect: (newToken: {
        caretPosition: 'start' | 'end' | 'next' | number;
        text: string;
      }) => void;
      values: Parameters<Parameters<TriggerSettings<Co, Us, V>[key]['dataProvider']>[2]>[0];
      className?: string;
      itemClassName?: string;
      itemStyle?: CSSProperties;
      style?: CSSProperties;
      value?: string;
    };
  }
>;

export type ChatAutoCompleteProps = {
  /** Function to override the default submit handler on the underlying `textarea` component */
  handleSubmit?: (event: BaseSyntheticEvent) => void;
  /** Function to override the default onChange behavior on the underlying `textarea` component */
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  /** Function to run on focus of the underlying `textarea` component */
  // onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  /** Function to override the default onPaste behavior on the underlying `textarea` component */
  // onPaste?: (event: ClipboardEvent<HTMLTextAreaElement>) => void;
  /** Placeholder for the the underlying `textarea` component */
  placeholder?: string;
  /** The initial number of rows for the underlying `textarea` component */
  // rows?: number;
  /** The text value of the underlying `textarea` component */
  value?: string;
};

const UnMemoizedChatAutoComplete = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>(
    props: ChatAutoCompleteProps,
  ) => {

  const { t } = useTranslationContext('ChatAutoComplete')
  const i18next = useTranslation(['chat'])
  const { channel } = useChannelStateContext()
  const messageInput = useMessageInputContext<At, Ch, Co, Ev, Me, Re, Us, V>('ChatAutoComplete')
  const { cooldownRemaining } = messageInput
  const isChannelArchived = channel.data.status === 'inactive'

  let placeholder = props.placeholder || t('Type your message')
  if (isChannelArchived) {
    placeholder = i18next.t('chat:conversation.messageInput.disabledPlaceholder')
  }
  if (cooldownRemaining) {
    placeholder = t('Slow Mode ON')
  }

  return (
    <TextInput
      editable={!isChannelArchived && !cooldownRemaining}
      numberOfLines={messageInput.maxRows}
      onChange={props.onChange || messageInput.handleChange}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          if (props.handleSubmit) {
            props.handleSubmit(e)
          } else if (messageInput.handleSubmit) {
            messageInput.handleSubmit(e)
          }
        }
      }}
      placeholder={placeholder}
      value={props.value || messageInput.text}
      style={tailwind('border border-basic-lighter rounded-full p-3 flex-grow mr-2 text-basic-base text-sm font-medium')}
    />
  )
}

export const ChatAutoComplete = memo(
  UnMemoizedChatAutoComplete,
) as typeof UnMemoizedChatAutoComplete
