import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
} from '@fortawesome/pro-regular-svg-icons'
import { useHeaderHeight } from '@react-navigation/elements'
import pick from 'lodash/pick'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, useWindowDimensions } from 'react-native'

import analytics from '~/analytics'
import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { RecommendedContentFromScenario } from '~/components/sections/content/RecommendedContent'
import { cardMaxHeight, cardTopMargin, useCardFooterHeight } from '~/constants/ContentPlayerContainer.constants'
import { ScrollView } from '~/elements/containers/ScrollView'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { Text } from '~/elements/text/Text'
import { useFormattedContentById } from '~/hooks/dato.content'
import { useUser } from '~/hooks/user.context'
import { functionsEU } from '~/navigators.shared/app.firebase.client'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { handleContentListEvent } from '~/services/firebase.functions'
import { prefixMatch } from '~/theme/tailwind'

import { ContentPlayerDataWrapper } from '../ContentPlayerDataWrapper'
import { ContentPlayerProgressBar } from '../ContentPlayerProgressBar'
import { OutroCtaSection } from './OutroCtaSection'

library.add(faCheck)

const recommendationParams = {
  key: 'recommended-content',
  nbItems: 1,
  scenario: 'Home_ForYou',
}

const getRecommendationParams = (data, { filterCategory }, t) => {

  const res = { ...recommendationParams }

  const reQLExpArr = []

  // exclude that article from recommendations
  const excludeArticleByIdQuery = `'itemId' not in {"${data?.id}"}`
  reQLExpArr.push(excludeArticleByIdQuery)

  // Filter only articles in the same category
  const filterByArticleCategoryQuery = `"${data.groups[0]?.id}" in 'categories'`
  if (filterCategory) {
    reQLExpArr.push(filterByArticleCategoryQuery)
  }

  // consolidate query
  const reQLExp = `(${reQLExpArr.join(') and(')})`
  // console.log('reQLExp', reQLExp.replace(/\\"/, '"'))

  res.filter = reQLExp.replace(/\\"/, '"')
  res.title = filterCategory ? t('player:outro.recommended_title') : undefined
  res.tileSettings = {
    //  size: 'small'
    orientation: 'landscape',
  }

  return res
}

const HeaderTitle = () => (
  <View tw="w-full max-w-xlp justify-center items-center mt-2 mb-2">
    <View tw="rounded-full bg-brand-base h-6 w-6 text-surface-1 justify-center items-center">
      <Icon sizeNum={13} name="check" />
    </View>
  </View>
)

const ContentPlayerOutroScreen = ({ route }) => {
  const { contentId } = route.params
  // console.log(`-> CoverPage for ${contentId}`)
  const { isLoading, error, data } = useFormattedContentById(contentId)
  const instantHeaderHeight = useHeaderHeight()
  const cardFooterHeight = useCardFooterHeight()

  const { height } = useWindowDimensions()
  // console.log('screen height', height)

  const [headerHeight, setHeaderHeight] = useState(0)
  const [dimensions, setDimensions] = useState({ width: 960, height: 64 })

  const md = prefixMatch('md')

  useEffect(() => {
    if (instantHeaderHeight > 0 && instantHeaderHeight !== headerHeight) {
      setHeaderHeight(instantHeaderHeight)
    }
  }, [instantHeaderHeight, headerHeight])

  const { user } = useUser()
  const userId = user?.uid

  const { t } = useTranslation(['player'])

  useEffect(() => {
    // mark as completed
    analytics.track('playing-progressed', { itemId: contentId, portion: 1 })

    const eventData = {
      src: `${Platform.OS}-app`,
      uid: userId,
      itemId: contentId,
      action: 'add',
      list: 'history',
      agent: 'user',
    }

    handleContentListEvent(eventData, functionsEU)

    // purposly ignored 'functions'
  }, [contentId, userId])

  if (error) {
    return <ErrorScreen error={error} />
  }
  if (isLoading || !data) {
    return null
  }

  // console.log('data', data)

  const minAroundHeight = headerHeight + cardTopMargin + cardFooterHeight
  // console.log('minAroundHeight', minAroundHeight)
  const cardHeight = Math.min(height - minAroundHeight, cardMaxHeight - cardFooterHeight)

  const recoInSameCategoryParams = getRecommendationParams(data, { filterCategory: true }, t)
  const allRecoParams = getRecommendationParams(data, { filterCategory: false }, t)

  const Secondary = <OutroCtaSection style={{ height: cardFooterHeight }} />

  return (
    <ContentPlayerDataWrapper contentId={contentId}>
      <FullOverlayContainer tw="items-center flex-col bg-surface-brand" innerContainerTw="h-full">
        <View tw="flex-col">

          {/* Progress bar as false header */}
          {md && (
            <View
              tw="md:w-10/12 self-center"
              style={{ height: 64 }}
              onLayout={(event) => {
                const layoutDimensions = event.nativeEvent.layout
                if (setDimensions) {
                  setDimensions(pick(layoutDimensions, ['width', 'height']))
                }
              }}
              >
              <ContentPlayerProgressBar style={{ width: dimensions.width }} />
            </View>
          )}

          {/* Rest of the layout */}
          <TwoColGridSection padded Secondary={md ? Secondary : null} secondaryJustify="justify-start">
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerTw="flex-col items-center pb-24 w-full"
              >
              {!md && <HeaderTitle />}

              {/* Card */}
              <View
                tw="w-full"
                style={{
                  marginTop: cardTopMargin,
                  minHeight: 250,
                  height: cardHeight,
                }}
                >
                <Text tw="mb-8 font-serif font-semibold text-xl text-center">{t('player:outro.title')}</Text>
                {!md && <OutroCtaSection style={{ height: cardFooterHeight }} />}
                {
                  data.groups.length > 0
                    ? <RecommendedContentFromScenario {...recoInSameCategoryParams} tw="px-0 md:px-0" />
                    : <RecommendedContentFromScenario {...allRecoParams} tw="w-full" />
                }
              </View>
              <View tw="bg-red-500 h-[211] w-[223]" />
              {!md && <OutroCtaSection style={{ height: cardFooterHeight }} />}

            </ScrollView>
          </TwoColGridSection>
        </View>
      </FullOverlayContainer>
    </ContentPlayerDataWrapper>
  )
}

export default ContentPlayerOutroScreen
