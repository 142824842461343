/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
// const plugin = require('tailwindcss/plugin')
const themeColors = require('./theme/colors')

// console.log('themeColors', themeColors)

module.exports = {
  content: [
    'elements/**/*.(ts|tsx|js|jsx)',
    'components/**/*.(ts|tsx|js|jsx)',
    'screens/**/*.(ts|tsx|js|jsx)',
    'navigators/**/*.(ts|tsx|js|jsx)',
    // 'utils/**/*.(ts|tsx|js|jsx)',
  ],
  theme: {
    fontFamily: {
      sans: ['Poppins'], // 'Inter Var' // , ...defaultTheme.fontFamily.sans
      serif: ['Recoleta'], // ...defaultTheme.fontFamily.serif
    },
    colors: themeColors,
    extend: {
      spacing: {
        62: '15.5rem' /* 248px */,
        78: '19.5rem' /* 312px */,
        96: '24rem' /* 384px */,
        104: '26rem' /* 416px */,
        128: '32rem' /* 512px */,
        144: '36rem' /* 576px */,
        160: '40rem' /* 640px */,
        168: '42rem' /* 672px */,
        192: '48rem' /* 768px */,
        208: '52rem' /* 832px */,
        368: '92rem' /* 1472px */,
      },
      fontSize: {
        xs: '0.625rem', /* 10px */
        sm: '0.875rem', /* 14px */
        base: '1rem', /* 16px */
        lg: '1.25rem', /* 20px */
        xl: '1.5rem', /* 24px */
        '2xl': '2rem', /* 32px */
        '3xl': '2.5rem', /* 40px */
        '4xl': '3rem', /* 48px */
        '5xl': '4rem', /* 64px */
        '6xl': '5rem', /* 80px */
      },
      // Need to rewrite with units for RN
      // TODO in REM ?
      aspectRatio: {
        1: '1px',
        2: '2px',
        3: '3px',
        4: '4px',
        5: '5px',
        6: '6px',
        7: '7px',
        8: '8px',
        9: '9px',
        10: '10px',
        11: '11px',
        12: '12px',
        13: '13px',
        14: '14px',
        15: '15px',
        16: '16px',
      },
      ringColor: {
        DEFAULT: 'transparent',
      },
      minWidth: {
        1: '0.25rem', // 4px
        4: '1rem', // 16px
        8: '2rem', // 32px
        12: '3rem', // 48px
        16: '4rem', // 64px
        24: '6rem', // 96p
        32: '8rem', // 128px
        48: '12rem', // 192px
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
      },
      maxWidth: {
        xlp: '40rem', // 576px (xl-padding with extra 32px to account for card shaddows)
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1536px',
    },
    vscreens: {
      smv: '640px',
      mdv: '812px',
      lgv: '926px',
      xlv: '1600px',
    },
  },
  plugins: [
    // require('@tailwindcss/typography'),
    // require('@tailwindcss/forms'),
    // require('@tailwindcss/aspect-ratio'),

    /**
     * custom plugin that adds css gradient
     */
    // plugin(({ addComponents }) => {
    //   addComponents({
    //     '.svg-settings': {
    //       /* Hiding this content since it's just settings. See - https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
    //       position: 'absolute !important',
    //       height: '1px',
    //       width: '1px',
    //       overflow: 'hidden',
    //       clip: 'rect(1px 1px 1px 1px)', /* IE6, IE7 */
    //     },

    //     /* linear gradient */
    //     '.fill-gradient-linear path': {
    //       fill: 'url(#linear)',
    //     },

    //     /* setting colors for linear gradient */
    //     '.linear-stop1': {
    //       stopColor: '#FFE7AB',
    //       stopOpacity: 0.7,
    //     },

    //     '.linear-stop2': {
    //       stopColor: '#FF965A',
    //       stopOpacity: 0.9,
    //     },
    //   })
    // }),

    /**
     * custom plugin that adds modifiers for vertical breakpoints
     */
    // plugin(({ addVariant }) => {
    //   addVariant('smv', '@media (min-height: 640px)') // iPhone 4S resolution (640 x 960)
    //   addVariant('mdv', '@media (min-height: 812px)') // iPhone X (375 x 812)
    //   addVariant('lgv', '@media (min-height: 926px)') // iPhone 13 Pro Max (1284 x 2778)
    //   addVariant('xlv', '@media (min-height: 1600px)') // mac book pro 13" 4th gen (2560 x 1600)
    // }),

    /**
     * Utilities that effectively map variants to screen height
     */
    // plugin(({ matchUtilities, theme }) => {
    //   matchUtilities(
    //     {
    //       tab: (value) => ({
    //         vscreen: value,
    //       }),
    //     },
    //     { values: theme('vscreen') },
    //   )
    // }),
  ],
}
