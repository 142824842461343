import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar, faClock,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'
import { StackScreenProps } from '@react-navigation/stack'
import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { EventuallyJoinButton } from '~/components/panels/cards/EventuallyJoinButton'
import { MeetingData } from '~/definitions/firestore.meeting'
import { Avatar } from '~/elements/avatar/Avatar'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
import { Icon } from '~/elements/icons/Icon'
import { Text } from '~/elements/text/Text'
import { useDateFormat, useDurationFormat } from '~/hooks/dateFns'
import { useMinderBySlug } from '~/hooks/dato.minders'
import { useSessionBySlug } from '~/hooks/dato.sessions'
import { useMeetingById } from '~/hooks/firestore.meetings'
import { useIsPast } from '~/hooks/time'
import { MyTrackStackParamList } from '~/navigators/MyTrackStackParamList'
import { getFullName } from '~/utils/people.helpers'

library.add(faCalendar, faXmark, faClock)

type SessionScreenProps = StackScreenProps<MyTrackStackParamList, 'Session'>

const SessionDetails = ({ meeting }:{meeting:MeetingData}) => {
  // console.log('-> SessionDetails', meeting)
  const { isLoading, data } = useSessionBySlug(meeting.type)
  // console.log(`useSessionBySlug data`, data)

  const minderData = useMinderBySlug(meeting.minder)
  const { t } = useTranslation(['track'])
  const dateString = upperFirst(useDateFormat(meeting.start_time, 'longWithTime'))
  const durationString = useDurationFormat({
    minutes: data?.session?.duration || 30,
  })

  const isPast = useIsPast(meeting.end_time)

  if (isLoading || !data || minderData.isLoading) {
    return null
  }

  // console.log('meeting', meeting)
  //   console.log('foo', foo.data?.minder)

  const { title, description } = data.session

  const picture = get(minderData, 'data.contractor.minder.picture')
  const fullName = getFullName(get(minderData, 'data.contractor.minder'))

  return (
    <MainContainer scrollEnabled contentContainerTw="flex-col items-start w-full">
      <BasicSectionContainer tw="pt-20 flex-col content-start md:content-center">
        <View>
          <Text tw="font-serif text-xl">{title || t('track:session.title')}</Text>
          <View tw="flex-row mt-2  items-center">
            <Icon tw="" name="calendar" variant="regular" />
            <Text tw="ml-2 text-sm">{dateString}</Text>

          </View>
          <View tw="flex-row mt-2  items-center">
            <Icon tw="" name="clock" variant="regular" />
            <Text tw="ml-2 text-sm">{durationString}</Text>
          </View>
          {picture && picture.responsiveImage && (
            <View tw="flex-row justify-start mt-8">
              <Avatar rounded size="large" image={picture} tw="border-4 border-white bg-brand-base" />
              <View tw="ml-4 justify-center">
                <Text tw="text-base font-medium font-sans">{fullName}</Text>
                {/* {metadata && <Text tw="">{`${Math.round(metadata.minutes)} min reading`}</Text>} */}
              </View>
            </View>
          )}
        </View>
        {meeting.status === 'planned' && !isPast && (
          <EventuallyJoinButton
            tw="mt-4"
            startTime={meeting.start_time}
            meetingUrl={meeting.vendors.zoom.joinUrl}
          />
        )}
        <View>
          <SectionHeading title={t('track:session.presentation')} />
          <Text tw="">{description}</Text>
        </View>

        {meeting.status === 'planned' && !isPast && (
          <View tw="mt-12">
            <Button
              iconRight={false}
              variant="outline"
              tw="justify-start"
              title={t('track:session.rescheduleBtn')}
              to={`/sessions/${meeting.meetingId}/reschedule`}
              color="control"
              icon={{ name: 'calendar', variant: 'regular' }}
            />
            <Button
              tw="mt-1 justify-start"
              variant="outline"
              iconRight={false}
              title={t('track:session.cancelBtn')}
              to={`/sessions/${meeting.meetingId}/cancel`}
              color="control"
              icon={{ name: 'xmark', variant: 'regular' }}
            />
          </View>
        )}
      </BasicSectionContainer>
    </MainContainer>
  )
}

export const SessionScreen:FC<SessionScreenProps> = ({ route }) => {

  const { meetingId } = route.params
  //   const linkTo = useLinkTo()
  const { isLoading, data } = useMeetingById(meetingId)

  if (isLoading) {
    return null
  }

  return <SessionDetails meeting={{ ...data?.data(), meetingId }} />
}
