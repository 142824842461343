import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { EvaluationProvider } from '~/context/EvaluationContext'
import { useGetCurrentUserLatestEvaluationByType } from '~/hooks/firestore.evaluations'
import { ProfileStackParamList } from '~/navigators/ProfileStackParamList'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

// import { CheckupCta } from './CheckupCta'
import { MindexSection } from './MindexSection'
import { PersonalityTestCta } from './PersonalityTestCta'
import { PersonalityTestSection } from './PersonalityTestSection'

type ProfileScreenProps = StackScreenProps<ProfileStackParamList, 'Profile'>

export const ProfileScreen: FC<ProfileScreenProps> = () => {
//   console.log('-> ProfileScreen')
  const { t } = useTranslation(['profile'])
  const { isLoading, data: evaluation } = useGetCurrentUserLatestEvaluationByType('user-personalities')

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <MainContainer scrollEnabled contentContainerTw="flex-col items-start w-full">
      <ScreenHeadingSection tw="mb-4 md:mt-20" title={t('profile:main.heading.title')} />
      {/* <CheckupCta /> */}
      <MindexSection />
      {!evaluation && <PersonalityTestCta />}
      {evaluation && (
        <EvaluationProvider evaluationId={evaluation.id}>
          <PersonalityTestSection />
        </EvaluationProvider>
      )}
    </MainContainer>
  )
}
