import { useImageByIdQuery, useLottieByIdQuery, useSvgByIdQuery } from '~/__generated__/graphql.queries'

type UseImageOptions = {
  width: number
  height: number
}

export const useDatoImage = (id: string, { width, height }: UseImageOptions) => useImageByIdQuery({
  id,
  width: width || 640,
  height: height || 480,
}, { enabled: !!id })

export const useDatoLottie = (id: string) => useLottieByIdQuery({ id }, { enabled: !!id })

export const useDatoSvg = (id: string) => useSvgByIdQuery({ id }, { enabled: !!id })
