import { FC, useEffect, useState } from 'react'

import { Button } from '~/elements/buttons/Button'
import { ButtonColor } from '~/elements/buttons/Button.d'
import { View } from '~/elements/containers/View'
import { prefixMatch } from '~/theme/tailwind'

type OptionProps = {
  key: string
  label: string
}

export type MultipleChoiceSelectorProps = {
  multipleSelect?: boolean
  randomize?: boolean // whether we want options to appear shuffled or not
  values: Array<string>
  options: Array<OptionProps>
  onChange: (values: Array<string>) => void
  color?: ButtonColor
}

export const MultipleChoiceSelector: FC<MultipleChoiceSelectorProps> = ({
  options,
  values = [],
  onChange,
  color = 'basic',
  multipleSelect = true,
  randomize = false,
}) => {

  const [selected, setSelected] = useState<Array<string>>(values)

  const handleButtonPressed = (value: string) => {
    if (multipleSelect) {
      if (selected.includes(value)) {
        const newSelected = selected.filter((item) => item !== value)
        setSelected(newSelected)
      } else {
        setSelected([...selected, value])
      }
    } else if (selected.includes(value)) {
      setSelected([value])
    } else {
      setSelected([])
    }
  }

  useEffect(() => {
    onChange(selected)
  }, [selected]) // onChange purposedely excluded bc it would trigger "maximum depth exceeded"

  const displayedOptions = randomize ? options.sort(() => Math.random() - 0.5) : options

  return (
    <View tw="flex-row flex-wrap mt-2">
      {displayedOptions.map((option) => {
        const isSelected = selected.includes(option.key)
        return (
          <Button
            tw="m-1"
            size={prefixMatch('md') ? 'base' : 'sm'}
            key={option.key}
            title={option.label}
            color={color}
            variant={isSelected ? 'filled' : 'outline'}
            onPress={() => handleButtonPressed(option.key)}
          />
        )
      })}
    </View>
  )

}
