import LottieView, { AnimatedLottieViewProps } from 'lottie-react-native'
import { FC } from 'react'

import { useAxiosGet } from '~/hooks/axios.get'
import { useDatoLottie } from '~/hooks/dato.assets'
import { tailwind } from '~/theme/tailwind'

// TODO wrap this query with react-query in order to normalize query interface

type AnimationProps = AnimatedLottieViewProps & {
  itemId:string
  tw?:string
}

/**
 * Fetch lottie data from Dato CMS and return it as a Lottie View
 */
export const Animation: FC<AnimationProps> = ({ itemId, tw }) => {

  const { data: datoRecord } = useDatoLottie(itemId)
  const { data: lottieData } = useAxiosGet(datoRecord?.upload?.url)

  // console.log('lottieData', lottieData, typeof lottieData)

  if (!lottieData) {
    return null
  }

  return <LottieView source={lottieData} style={tailwind(tw || '')} />
}
