import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { ViewStyle } from 'react-native'

import { View } from '~/elements/containers/View'
import { prefixMatch } from '~/theme/tailwind'

import { BasicSectionContainer } from './SectionContainer'

type ThreeColGridSectionProps = {
  tw?: string

  style?: ViewStyle

  /**
   * Content of the left section (on a rtl screen)
   */
  Left?: ReactNode

  /**
   * Content of the right section (on a rtl screen)
   */
  Right?: ReactNode

  reverse?:boolean

}

/**
 * A container that displays 3/6/3 columns of content.
 * Typically used on false modal screens such as Preferences or Notifications.
 */
export const ThreeColGridSection: FC<ThreeColGridSectionProps> = ({
  tw, style, Left, Right, children, reverse,
}) => {

  // On smaller screen, return the default single column section
  if (!prefixMatch('md')) {
    return <BasicSectionContainer style={style}>{children}</BasicSectionContainer>
  }

  const flexDirectionTw = reverse ? 'flex-row-reverse' : 'flex-row'

  const containerTw = classNames('w-full justify-between', flexDirectionTw, tw)
  const centralColumnTw = 'w-6/12 flex-col justify-center pl-4 xl:pl-0'
  const sideColumnTw = 'w-3/12 flex-col justify-center'

  return (
    <View tw={containerTw} style={style}>
      <View tw={sideColumnTw} style={style}>{ Left ? <Left /> : null}</View>
      <View tw={centralColumnTw} style={style}>{children}</View>
      <View tw={sideColumnTw} style={style}>{ Right ? <Right /> : null}</View>
    </View>
  )
}

ThreeColGridSection.defaultProps = {

  reverse: false,
}
