import noop from 'lodash/noop'
import {
  ComponentType, FC, useCallback, useEffect, useState,
} from 'react'
import { Platform } from 'react-native'

import { Avatar } from '~/elements/avatar/Avatar'
import { Pressable } from '~/elements/containers/Pressable'
import { View } from '~/elements/containers/View'
import { Text } from '~/elements/text/Text'

import { useTranslationContext } from '../context/TranslationContext'
// import { useBreakpoint } from '../Message/hooks/useBreakpoint'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from '../stream.types'
import { ChannelOrUserResponse, isChannel } from './utils'

export type DropdownContainerProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  results: ChannelOrUserResponse<At, Ch, Co, Ev, Me, Re, Us>[];
  SearchResultItem: ComponentType<SearchResultItemProps<At, Ch, Co, Ev, Me, Re, Us>>;
  selectResult: (result: ChannelOrUserResponse<At, Ch, Co, Ev, Me, Re, Us>) => Promise<void> | void;
  focusedUser?: number;
};

const DefaultSearchResultItem = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: SearchResultItemProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const {
    // focusedUser, index,
    result, selectResult,
  } = props

  // const focused = focusedUser === index

  if (isChannel(result)) {
    const channel = result

    return (
      <Pressable
        onPress={() => selectResult(channel)}
        >
        <View>#</View>
        <Text>{channel.data?.name}</Text>
      </Pressable>
    )
  }
  return (
    <Pressable
      onPress={() => selectResult(result)}
      >
      <Avatar
        image={result.image}
        // user={result}
      />
      {result.name || result.id}
    </Pressable>
  )

}

const DefaultDropdownContainer = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: DropdownContainerProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const {
    focusedUser, results, SearchResultItem = DefaultSearchResultItem, selectResult,
  } = props

  return (
    <View>
      {results.map((result, index) => (
        <SearchResultItem
          focusedUser={focusedUser}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          result={result}
          selectResult={selectResult}
        />
      ))}
    </View>
  )
}

export type SearchResultItemProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  // index: number;
  result: ChannelOrUserResponse<At, Ch, Co, Ev, Me, Re, Us>;
  selectResult: (result: ChannelOrUserResponse<At, Ch, Co, Ev, Me, Re, Us>) => Promise<void> | void;
  // focusedUser?: number;
};

export type SearchResultsProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  results: Array<ChannelOrUserResponse<At, Ch, Co, Ev, Me, Re, Us>> | [];
  searching: boolean;
  selectResult: (result: ChannelOrUserResponse<At, Ch, Co, Ev, Me, Re, Us>) => Promise<void> | void;
  DropdownContainer?: ComponentType<DropdownContainerProps<At, Ch, Co, Ev, Me, Re, Us>>;
  // popupResults?: boolean;
  SearchEmpty?: ComponentType;
  SearchLoading?: ComponentType;
  SearchResultItem?: ComponentType<SearchResultItemProps<At, Ch, Co, Ev, Me, Re, Us>>;
  SearchResultsHeader?: ComponentType;
};

export const SearchResults = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: SearchResultsProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const {
    DropdownContainer = DefaultDropdownContainer,
    // popupResults,
    results,
    searching,
    SearchEmpty,
    SearchResultsHeader,
    SearchLoading,
    SearchResultItem = DefaultSearchResultItem,
    selectResult,
  } = props

  const { t } = useTranslationContext('SearchResults')

  const [focusedUser, setFocusedUser] = useState<number>()

  // const { device } = useBreakpoint()

  // const containerStyle = popupResults && device === 'full' ? 'popup' : 'inline'

  // eslint-disable-next-line react/no-unstable-nested-components
  const ResultsContainer: FC = ({ children }) => (
    <View>{children}</View>
  )

  const handleKeyDown = useCallback(
    // eslint-disable-next-line consistent-return
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) {
            return 0
          }
          return prevFocused === 0 ? results.length - 1 : prevFocused - 1
        })
      }

      if (event.key === 'ArrowDown') {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) {
            return 0
          }
          return prevFocused === results.length - 1 ? 0 : prevFocused + 1
        })
      }

      if (event.key === 'Enter') {
        event.preventDefault()
        if (focusedUser !== undefined) {
          selectResult(results[focusedUser])
          return setFocusedUser(undefined)
        }
      }
    },
    [focusedUser],
  )

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.addEventListener('keydown', handleKeyDown, false)
      return () => document.removeEventListener('keydown', handleKeyDown)
    }
    return noop()
  }, [handleKeyDown])

  if (searching) {
    return (
      <ResultsContainer>
        {SearchLoading ? (
          <SearchLoading />
        ) : (
          <Text>{t('Searching...')}</Text>
        )}
      </ResultsContainer>
    )
  }

  if (!results.length) {
    return (
      <ResultsContainer>
        {SearchEmpty ? (
          <SearchEmpty />
        ) : (
          <Text>{t('No results found')}</Text>
        )}
      </ResultsContainer>
    )
  }

  return (
    <ResultsContainer>
      {SearchResultsHeader && <SearchResultsHeader />}
      <DropdownContainer
        focusedUser={focusedUser}
        results={results}
        SearchResultItem={SearchResultItem}
        selectResult={selectResult}
      />
    </ResultsContainer>
  )
}
