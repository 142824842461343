import {
  ComponentType, FC,
} from 'react'

// import type { Channel } from 'stream-chat'
// ChannelListMessengerProps as RnChannelListMessengerProps,
import { View } from '~/elements/containers/View'

import { ChatDown, ChatDownProps } from '../ChatDown/ChatDown'
import { LoadingChannels } from '../Loading/LoadingChannels'
// import { LoadingChannels } from '../Loading/LoadingChannels'
import type {
  // DefaultAttachmentType,
  // DefaultChannelType,
  // DefaultCommandType,
  // DefaultEventType,
  // DefaultMessageType,
  // DefaultReactionType,
  DefaultUserType,
} from '../stream.types'

export type ChannelListMessengerProps<
  // At extends DefaultAttachmentType = DefaultAttachmentType,
  // Ch extends DefaultChannelType = DefaultChannelType,
  // Co extends DefaultCommandType = DefaultCommandType,
  // Ev extends DefaultEventType = DefaultEventType,
  // Me extends DefaultMessageType = DefaultMessageType,
  // Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  /** Whether or not the channel query request returned an errored response */
  error?: boolean;
  /** The channels currently loaded in the list, only defined if `sendChannelsToList` on `ChannelList` is true */
  // loadedChannels?: Channel<At, Ch, Co, Ev, Me, Re, Us>[];
  /** Whether or not channels are currently loading */
  loading?: boolean;
  /** Custom UI component to display a loading error, defaults to and accepts same props as: [ChatDown](https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChatDown/ChatDown.tsx) */
  LoadingErrorIndicator?: ComponentType<ChatDownProps>;
  /** Custom UI component to display a loading indicator, defaults to and accepts same props as: [LoadingChannels](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Loading/LoadingChannels.tsx) */
  LoadingIndicator?: ComponentType;
  /** Local state hook that resets the currently loaded channels */
  // setChannels?: Dispatch<SetStateAction<Channel<At, Ch, Co, Ev, Me, Re, Us>[]>>;
};

/**
 * A preview list of channels, allowing you to select the channel you want to open
 */
export const ChannelListMessenger:FC<ChannelListMessengerProps> = (props) => {
  const {
    children,
    error = false,
    loading,
    LoadingErrorIndicator = ChatDown,
    LoadingIndicator = LoadingChannels,
  } = props

  if (error) {
    return <LoadingErrorIndicator type="Connection Error" />
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <View tw="">
      <View tw="">{children}</View>
    </View>
  )
}
