import cleanDeep from 'clean-deep'
import { View as RnView } from 'dripsy'
import {
  ComponentProps, FC, forwardRef, useRef,
} from 'react'

import { useStateAwareTailwind } from '~/hooks/tailwind'
import { getAspectRatioRelatedStyle } from '~/utils/tailwind/tw.aspectRatio.helpers'

import { adaptOutlineClasses, processWauto } from './tw.helpers'
// import { Icon } from '../Icon/Icon'

export type ViewProps = {
  /**
   * Background surface level
   */
  level?: 1 | 2 | 3 | 4 | 'brand'

  /**
   * Tailwind classes
   */
  tw?: string

} & ComponentProps<typeof RnView>

/**
 * TODO
 * - Dark Theme
 * - includes only required selectors
 */
export const View: FC<ViewProps> = forwardRef((props, ref) => {
  const {
    children, level, tw = '', style = {}, ...restProps
  } = props
  // console.log('View', props)
  // console.log('tw', tw)
  // console.log('style', style)

  if (tw.includes('undefined')) {
    throw new Error('View: tw cannot include undefined')
  }

  const newRef = useRef(null)
  const pressableRef = ref || newRef

  const tailwind = useStateAwareTailwind(pressableRef)

  // w-auto => we remove width definition from smaller resolutions

  const newTw = processWauto(tw)
  // console.log(`newTw`, newTw)

  const adaptOutlineClassesRes = adaptOutlineClasses(newTw)

  const { aspectRatio, nonAspectRatioTw } = getAspectRatioRelatedStyle(adaptOutlineClassesRes.tw)

  const viewStyle = cleanDeep({
    ...tailwind(level ? `bg-surface-${level}` : ''),
    aspectRatio,
    ...tailwind(nonAspectRatioTw),
    ...adaptOutlineClassesRes.style,
    ...style,
  })

  // console.log('viewStyle', viewStyle)

  return (
    <RnView ref={pressableRef} style={viewStyle} {...restProps}>{children}</RnView>
  )
})
