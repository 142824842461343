// import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { Timestamp } from 'firebase/firestore'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ConversationCard } from '~/components/cards/Conversation/ConversationCard'
import { HotContentCard } from '~/components/cards/HotContent/HotContentCard'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { Text } from '~/elements/text/Text'
import { useGenericChannels } from '~/hooks/stream.channels'
import { useUser } from '~/hooks/user.context'
import { HomeStackParamList } from '~/navigators/HomeStackParamList'
import { getFirstName } from '~/utils/people.helpers'

import LoadingScreen from '../LoadingScreen/LoadingScreen'

export type DashboardScreenProps = StackScreenProps<HomeStackParamList, 'Dashboard'>

// const homeBgGradientTopAssetId = '28838982'
// const homeBgColor = 'brand-white'

/**
 * This screen is transverse to multiple functionalities
 * and present a highly personalised content
 * It's home to the action list and recommended content
 */
export const HomeScreen: FC<DashboardScreenProps> = () => {
  // const linkTo = useLinkTo()

  const insets = useSafeAreaInsets()
  const { user } = useUser()
  // const hasUserCompletedDetails = useContactDetailsData()
  // console.log('-> HomeScreen C')
  // console.log('hasUserCompletedDetails', hasUserCompletedDetails)
  // useTemporaryBackground({ bgColor: homeBgColor, bgSvgId: homeBgGradientTopAssetId })

  // const { data: evaluation } = useGetCurrentUserLatestEvaluationByType('user-personalities')

  const { t } = useTranslation(['home'])

  const {
    isLoading: isLoadingGenericChannels,
    channels,
  } = useGenericChannels()

  // temporarily return null while we load local storage value
  if (isLoadingGenericChannels) {
    return <LoadingScreen />
  }

  // TMP comment while we fix backend issue
  // if (!hasUserCompletedDetails) {
  //   linkTo('/onboarding')
  // }

  const firstName = getFirstName(user.displayName)

  let greeting = t('home:greeting')
  if (firstName) {
    greeting += ` ${firstName}`
  }
  greeting += ' !'

  let lastMessageTimestamp
  if (channels.length > 0) {
    lastMessageTimestamp = Timestamp.fromDate(channels[0].lastMessage)
  }

  return (
    <MainContainer tw="flex-col items-center md:items-start w-full" edges={['bottom']}>
      <BasicSectionContainer tw="bg-brand-base pb-6 flex-grow md:flex-none" style={{ paddingTop: insets.top }}>
        <Text tw="font-serif text-xl md:text-2xl px-4 md:pl-8 font-medium mt-4 md:mt-20">{greeting}</Text>
        <ConversationCard conversationId={channels.length === 0 ? undefined : channels[0].id} lastMessageTimestamp={lastMessageTimestamp} />
      </BasicSectionContainer>
      <BasicSectionContainer tw="flex-none">
        <HotContentCard />
      </BasicSectionContainer>
    </MainContainer>
  )
}
