import { useRoute } from '@react-navigation/core'
import classNames from 'classnames'
import { LinearGradient } from 'expo-linear-gradient'
import get from 'lodash/get'
import last from 'lodash/last'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useBottomFaderHeight, useCardFooterHeight } from '~/constants/ContentPlayerContainer.constants'
import { Button } from '~/elements/buttons/Button'
import { getLinearGradientStartEndPoint } from '~/elements/containers/gradient.helpers'
import { View } from '~/elements/containers/View'
import { useFormattedContentById } from '~/hooks/dato.content'
import { getHexColorFromTwColor } from '~/theme/helpers'
import { prefixMatch } from '~/theme/tailwind'

export type ReaderFooterProps = {
  // prevPath: string
  // nextPath: string
  // prevButtonTitle: string
  // nextButtonTitle: string
  shouldFadeContentAbove?: boolean
}

export const BottomContentNavBar: FC<ReaderFooterProps> = ({ height, bgColor, shouldFadeContentAbove = true }) => {
  const route = useRoute()
  const contentId = get(route, 'params.contentId')
  const { data } = useFormattedContentById(contentId)
  const { t } = useTranslation(['player'])

  const cardFooterHeight = useCardFooterHeight()
  const bottomFaderHeight = useBottomFaderHeight()

  if (!data) {
    return null
  }

  const { id, toc } = data

  const { pageNb } = route.params || {}

  // console.log('pageNb', pageNb)

  const index = Number(pageNb || 0) - 1

  // const isIntroPage = index === -1
  const isFirstPage = index === 0
  const isLastPage = index + 1 === toc.length

  let prevTarget = `page/${index}`
  // if (isIntroPage) {
  //   prevTarget = 'cover'
  // }
  if (isFirstPage) {
    prevTarget = undefined
  }

  const prevPath = `/content/${id}${prevTarget ? `/${prevTarget}` : ''}`
  const nextPath = `/content/${id}/${isLastPage ? 'outro' : `page/${index + 2}`}`

  let nextButtonTitle = t('next')
  if (isLastPage) {
    nextButtonTitle = t('complete')
  }

  // console.log(src, 'Footer with', `${last(prevPath.split('/'))} <=> ${last(nextPath.split('/'))}`)

  const { startPoint, endPoint } = getLinearGradientStartEndPoint('bg-gradient-to-t')

  return (
    <View tw="grow-0 w-full" style={{ height }}>

      {/* Fader */}
      {shouldFadeContentAbove && (
        <LinearGradient
          colors={[getHexColorFromTwColor(bgColor), getHexColorFromTwColor(bgColor), 'rgba(255,255,255,0)']} // 'transparent' isnt rendered as white on Ssfari but black. See https://stackoverflow.com/questions/38391457/linear-gradient-to-transparent-bug-in-latest-safari
          style={{ height: bottomFaderHeight, width: '100%', marginTop: -bottomFaderHeight }}
          locations={[0, 0.2, 1]}
          start={startPoint}
          end={endPoint}
        />
      )}

      {/* controls */}
      <View
        // eslint-disable-next-line max-len
        tw={classNames(`flex-row bg-${bgColor} justify-between items-center py-2 pl-4 md:pl-0 pr-4 md:pr-0 md:pb-6`, cardFooterHeight === height ? '' : 'rounded-b-xl md:rounded-none')}
        style={{ height: cardFooterHeight }}
        >
        <Button
          testID={`nav-left-${last(prevPath.split('/'))}`}
          nativeID={`nav-left-${last(prevPath.split('/'))}`}
          icon={{
            name: 'arrow-left',
            variant: 'regular',
          }}
          variant="ghost"
          iconRight={false}
          to={prevPath}
          hookKey="ArrowLeft"
        />
        <Button
          testID={`nav-right-${last(nextPath.split('/'))}`}
          nativeID={`nav-right-${last(nextPath.split('/'))}`}
          color="brand"
          iconRight
          title={nextButtonTitle}
          to={nextPath}
          hookKey="ArrowRight"
        />
        {/* Hidden button to vertically center main CTA */}
        {prefixMatch('md') && (
          <Button
            icon={{
              name: 'arrow-right',
              variant: 'regular',
              tw: 'text-transparent',
            }}
            variant="ghost"
            iconRight={false}
          />
        )}
      </View>
    </View>

  )
}
