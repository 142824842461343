import { create } from 'twrnc'

import twConfig from '../tailwind.config.native'

const tw = create(twConfig)

export const tailwind = tw.style

export const twMod = tw

export const { prefixMatch } = tw
