import { useRoute } from '@react-navigation/core'
import last from 'lodash/last'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useEvaluationContext } from '~/context/EvaluationContext'
import { Button } from '~/elements/buttons/Button'
import { View } from '~/elements/containers/View'
// import { getBaseButtonClasses } from '~/elements/buttons/button.helpers'

export type NavProps = {
  // prevPath?: string
  // nextPath?: string
  // prevButtonTitle?: string
  // nextButtonTitle?: string
  src?: string
  isDimensionGroup?: boolean
  position?: 'right' | 'left' | 'bottom'
  // how we display the dimension nav, choices for now being left arrow/right arrow/both arrows at bottom (default), might gain more use cases
  // bc of various uses of present component, all props became optional
}

export const EvaluationNavBar: FC<NavProps> = ({ src, isDimensionGroup, position = 'bottom' }) => {
  const { data: { doc: { id }, model: { toc } } } = useEvaluationContext()

  const { t } = useTranslation(['player'])

  const route = useRoute()

  const urlPageNb = route.params?.pageNb

  const pageNb = Number(urlPageNb)
  const index = pageNb - 1

  const isFirstPage = index === 0
  const isLastPage = pageNb === toc.length

  // A temporary route as we dont need intro at the moment but directly route to the main stack
  let prevPath = ''
  if (!isFirstPage) {
    prevPath += `/evaluations/${id}/page/${index}`
  }
  const nextPath = `/evaluations/${id}/${isLastPage ? 'outro' : `page/${index + 2}`}` // TODO here to change page number into slug, but think about how to get the order
  // const prevButtonTitle = isFirstPage ? t('player:intro') : t('player:previous')
  const nextButtonTitle = isLastPage ? t('player:complete') : t('player:next')

  // console.log(src, 'Footer with', `${last(prevPath.split('/'))} <=> ${last(nextPath.split('/'))}`)

  // const basicBtnContainerTw = getBaseButtonClasses()
  // console.log('basicBtnContainerTw', basicBtnContainerTw)

  // const ghostBtnContainerTw = getBaseButtonClasses({ variant: 'ghost' })
  // console.log('ghostBtnContainerTw', ghostBtnContainerTw)

  if (isDimensionGroup) {
    return (<Button tw="mt-8 w-min" title={t('player:continue')} to={nextPath} />)
  }

  if (position === 'left') {
    return (
      <Button
        testID={`${src.toLowerCase()}-left-${last(prevPath.split('/'))}`}
        nativeID={`${src.toLowerCase()}-left-${last(prevPath.split('/'))}`}
        variant="ghost"
        icon={{
          name: 'arrow-left',
          variant: 'regular',
        }}
        iconRight={false}
        // title={prevButtonTitle}
        to={prevPath}
        hookKey="ArrowLeft"
      />
    )
  }

  if (position === 'right') {
    return (
      <Button
        testID={`${src.toLowerCase()}-right-${last(nextPath.split('/'))}`}
        nativeID={`${src.toLowerCase()}-right-${last(nextPath.split('/'))}`}
        variant="ghost"
        icon={{
          name: 'arrow-right',
          variant: 'regular',
        }}
        iconRight={false}
        // title={prevButtonTitle}
        to={nextPath}
        hookKey="ArrowRight"
      />
    )
  }

  // default bottom navbar, as applied in mobile layout
  return (
    <View tw="flex-row justify-between mt-8">
      {/* now only ScoreDimensionPage uses this part, which means src props could be optional */}
      <Button
        testID={`${src.toLowerCase()}-left-${last(prevPath.split('/'))}`}
        nativeID={`${src.toLowerCase()}-left-${last(prevPath.split('/'))}`}
        variant="ghost"
        icon={{
          name: 'arrow-left',
          variant: 'regular',
        }}
        iconRight={false}
        // title={prevButtonTitle}
        to={prevPath}
        hookKey="ArrowLeft"
      />
      <Button
        testID={`${src.toLowerCase()}-right-${last(nextPath.split('/'))}`}
        nativeID={`${src.toLowerCase()}-right-${last(nextPath.split('/'))}`}
        color="brand"
        iconRight
        title={nextButtonTitle}
        to={nextPath}
        hookKey="ArrowRight"
      />
    </View>
  )
}
